import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Button } from 'reactstrap'
import Select from 'react-select'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { startCase, lowerCase, startsWith } from 'lodash'
import produce from 'immer'
import { MdInfoOutline } from 'react-icons/md'
import type { CharacterAttribute } from '../../../../reducers/characters'
import { getCharacters, setCharacters, getCharacterById } from '../../../../reducers/characters'
import { getAllCharacters } from '../../../characters/CharacterRoutes'
import {
	getParentsOfActionId,
	actionEventResultHasScreenAction,
	getQuestionOptionIndicesTriggeringActionId,
	getAppropriateFileRestrictions,
} from '../../helpers/algorithms'
import { handleJrStationChange, handleJrPlusStationChange } from '../../helpers/stations'
import MapObjectForm from '../../../maps/Map/MapObjects/MapObjectForm'
import { actions } from '../../../../setup/tiles'
import { selectors, useEditorSimulationActionsByType } from '../../../../reducers/simulationEditor'
import { specialEffectsList } from '../../../../helpers/specialEffects'
import {
	getSpecialEffectDisplay,
	VIDEO_SCREEN,
	VIDEO_OVERLAY,
	IMAGE_OVERLAY,
	IMAGE_SCREEN,
	missionEffectsList,
	ActionReference,
	ActionId,
} from '../../actionDefinitions'
import type {
	VocalTrackDisplayId,
	EventResult,
	AtTime,
	Action,
	ActivateStationData,
	JrPlusActivateStationData,
	JrActivateStationData,
	StationId,
	JrPlusStationId,
	ScreenAction,
	QuestionInfo,
	NumberPercentageDifference,
	TargetType,
	ActionMap,
	JrStationId,
} from '@mission.io/mission-toolkit/actions'
import {
	JR_PLUS_STATION_IDS,
	JR_STATION_IDS,
	TARGET_TYPE,
	SHARED_STATION_IDS,
	VOCAL_TRACK_DISPLAY_IDS,
} from '@mission.io/mission-toolkit/constants'
import FormComponentWrapper from '../FormComponentWrapper'
import type { EventResultKey, EventType } from '../../helpers/eventResults'
import { createOption, prettifyTypeEnum } from '../../../../helpers/functions'
import MarkDownInput from '../../../../common/MarkdownInput'
import { useNavigationMapMeta } from '../../../navigationMaps/hooks'
import { variantTypes } from '@mission.io/question-toolkit'
import {
	BooleanInput,
	ExtraMessage,
	MapObjectSelector,
	MapSelector,
	NumberInput,
	TimeInput,
	StringInput,
	UrlInput,
	Icon,
	EventResultInput,
} from './BasicFormComponents'
import { DefenseTargetsEditor, TractorBeamTargetsEditor } from './StationTargetsEditor'
import { CreativeCanvasStationDataEditor } from './CreativeCanvasEditor'
import type { EventResultChangeProps } from './BasicFormComponents'
import type { FileRestriction } from '../../helpers/algorithms'
import * as LiteracyEvent from './LiteracyEvent'
import { ReduxStore } from '../../../../types/ReduxStore'

export * from './BasicFormComponents'
export { LiteracyEvent }

export function AtTimeInput({
	value,
	onChange,
}: {
	value: AtTime<string>
	onChange: (e: unknown) => void
}): JSX.Element {
	return <div>Will have at time editor here</div>
}
export function SongUrlInput(props: {
	value: string
	onChange: (e: string | null | undefined) => void
	restrictions: Readonly<FileRestriction>
}): JSX.Element {
	return (
		<div>
			{!props.value && (
				<ExtraMessage>
					Since no song is selected, this action will turn off the current song
				</ExtraMessage>
			)}
			<UrlInput {...props} />
		</div>
	)
}
const CULMINATING_MOMENT_STUDENT_FIELDS_DISABLED_MARKDOWN_TAGS = ['a', 'img']
const CULMINATING_MOMENT_TEACHER_FIELDS_DISABLED_MARKDOWN_TAGS = ['a']
export function QuestionInfoInput({
	value,
	label,
	onChange,
	actions,
	actionId,
	onLeave,
}: {
	value: QuestionInfo<string>
	label: EventType
	onChange: (
		e:
			| EventResultChangeProps
			| {
					type: 'QUESTION_INFO'
					questionInfo: QuestionInfo<string>
			  }
	) => void
	actions?: (Action<string> | ActionReference)[]
	actionId: ActionId
	onLeave: (arg0: (arg0: boolean) => void) => () => void
}): JSX.Element {
	return (
		<div>
			<FormComponentWrapper
				label="Prompt"
				component={
					<MarkDownInput
						previewVertical={true}
						disabledComponents={CULMINATING_MOMENT_STUDENT_FIELDS_DISABLED_MARKDOWN_TAGS}
						value={value.phrase}
						onChange={(newPhrase) =>
							onChange({
								type: 'QUESTION_INFO',
								questionInfo: { ...value, phrase: newPhrase },
							})
						}
					/>
				}
			/>
			<FormComponentWrapper
				label="Description"
				component={
					<MarkDownInput
						previewVertical={true}
						disabledComponents={CULMINATING_MOMENT_TEACHER_FIELDS_DISABLED_MARKDOWN_TAGS}
						value={value.description}
						onChange={(newDescription) =>
							onChange({
								type: 'QUESTION_INFO',
								questionInfo: { ...value, description: newDescription },
							})
						}
					/>
				}
			/>
			<FormComponentWrapper
				label="Image"
				component={
					<UrlInput
						value={value.media[0]?.url}
						onChange={(newUrl) => {
							const newQuestionInfo = produce(value, (draft: QuestionInfo<string>) => {
								if (!newUrl) {
									draft.media = []
									return
								}

								draft.media = [
									{
										type: 'IMAGE',
										url: newUrl,
									},
								]
							})
							onChange({
								type: 'QUESTION_INFO',
								questionInfo: newQuestionInfo,
							})
						}}
						restrictions={getAppropriateFileRestrictions(
							'CULMINATING_MOMENT_SCREEN',
							'questionInfo'
						)}
					/>
				}
			/>
			{value.options.map(
				(
					option: {
						_id: string
						text: string
						result: EventResult<string>
						correct: boolean
						imageUrl?: string | null | undefined
						description: string
					},
					index
				) => {
					return (
						<OptionBox key={`option_${index}`}>
							<OptionHeaderRow>
								Option {index + 1}
								<Button
									className="ml-2"
									color="danger"
									size="sm"
									onClick={() => {
										const newQuestionInfo = produce(value, (draft: QuestionInfo<string>) => {
											draft.options.splice(index, 1)
										})
										onChange({
											type: 'QUESTION_INFO',
											questionInfo: newQuestionInfo,
										})
									}}>
									<Icon name="trash" />
								</Button>
							</OptionHeaderRow>
							<FormComponentWrapper
								label="Correct"
								component={
									<BooleanInput
										value={option.correct}
										onChange={(newCorrect) => {
											const newQuestionInfo = produce(value, (draft: QuestionInfo<string>) => {
												draft.options[index].correct = newCorrect
											})
											onChange({
												type: 'QUESTION_INFO',
												questionInfo: newQuestionInfo,
											})
										}}
									/>
								}
							/>
							<FormComponentWrapper
								label="Text"
								component={
									<MarkDownInput
										previewVertical={true}
										disabledComponents={CULMINATING_MOMENT_STUDENT_FIELDS_DISABLED_MARKDOWN_TAGS}
										value={option.text}
										onChange={(newText) => {
											const newQuestionInfo = produce(value, (draft: QuestionInfo<string>) => {
												draft.options[index].text = newText
											})
											onChange({
												type: 'QUESTION_INFO',
												questionInfo: newQuestionInfo,
											})
										}}
									/>
								}
							/>
							<FormComponentWrapper
								label="Description"
								component={
									<MarkDownInput
										previewVertical={true}
										disabledComponents={CULMINATING_MOMENT_TEACHER_FIELDS_DISABLED_MARKDOWN_TAGS}
										value={option.description}
										onChange={(newText) => {
											const newQuestionInfo = produce(value, (draft: QuestionInfo<string>) => {
												draft.options[index].description = newText
											})
											onChange({
												type: 'QUESTION_INFO',
												questionInfo: newQuestionInfo,
											})
										}}
									/>
								}
							/>
							<FormComponentWrapper
								label="Result"
								component={
									<EventResultInput
										label="questionInfo"
										actionId={actionId}
										value={option.result}
										onChange={(e) => {
											onChange({ ...e, optionIndex: index })
										}}
										actions={actions}
										onLeave={onLeave}
									/>
								}
							/>
							<FormComponentWrapper
								label="Image Url"
								component={
									<UrlInput
										value={option.imageUrl}
										onChange={(newUrl) => {
											const newQuestionInfo = produce(value, (draft: QuestionInfo<string>) => {
												draft.options[index].imageUrl = newUrl
											})
											onChange({
												type: 'QUESTION_INFO',
												questionInfo: newQuestionInfo,
											})
										}}
										restrictions={getAppropriateFileRestrictions(
											'CULMINATING_MOMENT_SCREEN',
											'questionInfo'
										)}
									/>
								}
							/>
						</OptionBox>
					)
				}
			)}
			<Button
				className="mt-3"
				color="primary"
				size="sm"
				onClick={() => {
					onChange({
						type: 'QUESTION_INFO',
						questionInfo: { ...value, options: [...value.options, createOption()] },
					})
				}}>
				Add Option
			</Button>
		</div>
	)
}
const OptionBox = styled.div`
	border: black solid 2px;
	border-radius: 4px;
	padding: 10px;
	margin: 3px 0;
`
const OptionHeaderRow = styled.div`
	font-size: 0.85rem;
	margin: 10px 0;
`
export function SpecialEffectInput({
	value,
	onChange,
}: {
	value: string
	onChange: (e: string) => void
}): JSX.Element {
	return (
		<Input type="select" onChange={(e) => onChange(e.currentTarget.value)} value={value}>
			{specialEffectsList.map((effectId) => {
				return (
					<option value={effectId} key={effectId}>
						{getSpecialEffectDisplay(effectId)}
					</option>
				)
			})}
		</Input>
	)
}
export function MissionEffectInput({
	value,
	onChange,
}: {
	value: string
	onChange: (e: string) => void
}): JSX.Element {
	return (
		<Input type="select" onChange={(e) => onChange(e.currentTarget.value)} value={value}>
			{missionEffectsList.map((missionEffectId) => {
				return (
					<option value={missionEffectId} key={missionEffectId}>
						{prettifyTypeEnum(missionEffectId)}
					</option>
				)
			})}
		</Input>
	)
}
export function NavigationMapSelector({
	value,
	onChange,
}: {
	value: string
	onChange: (e: string) => void
}): JSX.Element {
	const { meta: mapMetaList } = useNavigationMapMeta()
	return mapMetaList ? (
		<div css="display: flex">
			<Input type="select" onChange={(e) => onChange(e.currentTarget.value)} value={value}>
				<option value="">None</option>
				{mapMetaList.map((mapMeta) => {
					return (
						<option value={mapMeta._id} key={mapMeta._id}>
							{mapMeta.name}
						</option>
					)
				})}
			</Input>
			{value && (
				<Link target="_blank" css="margin-left: 4px" to={`editor/world/${value}`}>
					Edit Navigation Map
				</Link>
			)}
		</div>
	) : (
		<span>Loading...</span>
	)
}
export function CharacterSelector({
	value,
	onChange,
}: {
	value: string
	onChange: (e: string) => void
}): JSX.Element {
	const dispatch = useDispatch()
	const characters = useSelector(getCharacters)
	useEffect(() => {
		getAllCharacters().then((charactersFromServer) => dispatch(setCharacters(charactersFromServer)))
	}, [dispatch])
	return (
		<Input type="select" onChange={(e) => onChange(e.currentTarget.value)} value={value}>
			<option value="">None</option>
			{characters
				.sort((character1, character2) =>
					character1.name.localeCompare(character2.name, 'en', {
						sensitivity: 'base',
					})
				)
				.map((character) => {
					return (
						<option value={character._id} key={character._id}>
							{character.name}
						</option>
					)
				})}
		</Input>
	)
}
export function VocalTrackDisplaySelector({
	value,
	onChange,
}: {
	value: VocalTrackDisplayId
	onChange: (e: VocalTrackDisplayId) => void
}): JSX.Element {
	return (
		<Input
			type="select"
			onChange={(e) => onChange(e.currentTarget.value as VocalTrackDisplayId)}
			value={value}>
			<option value={VOCAL_TRACK_DISPLAY_IDS.ICON_ONLY}>Show Only Character Icon</option>
			<option value={VOCAL_TRACK_DISPLAY_IDS.SHOW_CHARACTER}>Show Character Card</option>
		</Input>
	)
}
export function AddMapObject({
	value,
	onChange,
	mapId,
}: {
	value: string
	onChange: (e: string) => void
	mapId: string
}): JSX.Element {
	const dispatch = useDispatch()
	useEffect(() => {
		if (mapId && value === '') {
			// This could add multiple map objects if we got into this effect multiple times. This useEffect is assuming that the above condition will only happen once, as soon as a mapId is selected
			dispatch(
				actions.maps.addObject({
					mapId,
					type: 'OTHER',
					hidden: true,
				})
			).then((updatedMap) => {
				if (!updatedMap || !updatedMap.data) {
					return
				}
				const myObject = updatedMap.data.objects.other[updatedMap.data.objects.other.length - 1]
				onChange(myObject._id)
			})
		}
	}, [dispatch, mapId, value, onChange])

	if (mapId !== '' && value !== '') {
		return (
			<div>
				<Message>Map Object automatically saves</Message>
				<MapObjectForm
					mapId={mapId}
					objectClassification="other"
					objectId={value}
					newItem={true}
					hideDelete={true}
				/>
			</div>
		)
	} else {
		return <div> No created object </div>
	}
}
const Message = styled.div`
	font-size: 1.2rem;
`
export function StationIdSelector({
	value,
	onChange,
	filter,
}: {
	value: StationId
	onChange: (e: StationId) => unknown
	filter?: (stationId: string) => boolean
}): JSX.Element {
	const isJunior = useSelector(selectors.isJunior)
	let stationIds = isJunior ? Object.keys(JR_STATION_IDS) : Object.keys(JR_PLUS_STATION_IDS)

	if (filter) {
		stationIds = stationIds.filter(filter)
	}

	return (
		<Input
			type="select"
			onChange={(e) => onChange(e.currentTarget.value as StationId)}
			value={value}>
			{!stationIds.includes(value) && <option></option>}
			{stationIds.map((stationId) => {
				return (
					<option value={stationId} key={stationId}>
						{startCase(lowerCase(stationId))}
					</option>
				)
			})}
		</Input>
	)
}
export function TargetTypeSelector({
	value,
	onChange,
}: {
	value: TargetType
	onChange: (e: TargetType) => void
}): JSX.Element {
	const targetTypes = Object.keys(TARGET_TYPE)
	return (
		<Input
			type="select"
			onChange={(e) => onChange(e.currentTarget.value as TargetType)}
			value={value}>
			{targetTypes.map((targetType) => {
				return (
					<option value={targetType} key={targetType}>
						{targetType}
					</option>
				)
			})}
		</Input>
	)
}
export function ScreenOverlaySelector({
	value,
	onChange,
	actionId,
}: {
	value: string
	onChange: (e: string) => void
	actionId: ActionId
}): JSX.Element {
	const actions = useSelector(selectors.getActions)

	const onChangeType = (type: string) => {
		// When changing to a screen
		if (type.includes('SCREEN') && actions) {
			// Find all actions that have the given actionId as an event result. (Parents)
			const parents = getParentsOfActionId(actionId, actions)
			const conflicts = parents.reduce((conflicts, { id: parentId, triggerForChild }) => {
				const params: [Action<string>, EventResultKey, ActionMap<string>] = [
					actions[parentId],
					triggerForChild,
					actions,
				]

				if (triggerForChild === 'questionInfo') {
					// Any question option indices where the parent references our action
					const optionIndices = getQuestionOptionIndicesTriggeringActionId(
						actions[parentId],
						actionId
					)
					optionIndices.forEach((optionIndex) => {
						// Check for conflicts at that optionIndex on the parent
						if (
							actionEventResultHasScreenAction(...params, {
								optionIndex,
							})
						) {
							conflicts += 1
						}
					}) // Check for conflicts at that parents event result
				} else if (actionEventResultHasScreenAction(...params)) {
					conflicts += 1
				}

				return conflicts
			}, 0)

			if (conflicts > 0) {
				toast.error(
					`You cannot turn this overlay into a screen because the event that 
					triggers this overlay already triggers a screen change`,
					{
						autoClose: false,
					}
				)
				return
			}
		}

		onChange(type)
	}

	return (
		<Input type="select" onChange={(e) => onChangeType(e.currentTarget.value)} value={value}>
			<option value={startsWith(value, 'VIDEO') ? VIDEO_OVERLAY : IMAGE_OVERLAY}>Overlay</option>
			<option value={startsWith(value, 'VIDEO') ? VIDEO_SCREEN : IMAGE_SCREEN}>Screen</option>
		</Input>
	)
}
export function PhaseCheckPointInput({
	value,
	onChange,
}: {
	value: ScreenAction<string>
	onChange: (e: ScreenAction<string>) => void
}): JSX.Element {
	const setProperty = (property: string, val: string) => {
		onChange({ ...value, [property]: val })
	}

	const removeProperty = (property: 'newPhase' | 'checkpoint') => {
		onChange(
			produce(value, (draft: ScreenAction<string>) => {
				delete draft[property]
			})
		)
	}

	return (
		<div>
			<FormComponentWrapper
				label="Make Phase"
				component={
					<BooleanInput
						value={Boolean(Boolean(value.newPhase) || value.newPhase === '')}
						onChange={(val) => (val ? setProperty('newPhase', '') : removeProperty('newPhase'))}
					/>
				}
			/>

			{(value.newPhase || value.newPhase === '') && (
				<FormComponentWrapper
					label="New Phase"
					component={
						<StringInput
							value={value.newPhase}
							onChange={(newPhase) => setProperty('newPhase', newPhase)}
						/>
					}
				/>
			)}

			<FormComponentWrapper
				label="Make Checkpoint"
				component={
					<BooleanInput
						value={Boolean(Boolean(value.checkpoint) || value.checkpoint === '')}
						onChange={(val) => (val ? setProperty('checkpoint', '') : removeProperty('checkpoint'))}
					/>
				}
			/>
			{(value.checkpoint || value.checkpoint === '') && (
				<FormComponentWrapper
					label="Checkpoint"
					component={
						<StringInput
							value={value.checkpoint}
							onChange={(checkpoint) => setProperty('checkpoint', checkpoint)}
						/>
					}
				/>
			)}
		</div>
	)
}
export function NumberPercentageInput({
	value,
	onChange,
}: {
	value: NumberPercentageDifference
	onChange: (arg0: NumberPercentageDifference) => void
}): JSX.Element {
	return (
		<div css="border: 1px dashed grey; padding: 6px;">
			<p>To decrease value, make amount negative</p>
			<div css="display: flex; align-items: center;">
				<div css="flex: 1;">
					<FormComponentWrapper
						label="Amount"
						component={
							<NumberInput
								value={value.amount}
								onChange={(amount) => onChange({ ...value, amount })}
							/>
						}
					/>
				</div>
				{value.isPercentage === true && <div>%</div>}
			</div>
			<FormComponentWrapper
				label=""
				component={
					<div css="text-align: right;">
						Use Percentage
						<BooleanInput
							value={value.isPercentage}
							onChange={(isPercentage) => {
								onChange({ ...value, isPercentage })
							}}
						/>
					</div>
				}
			/>
		</div>
	)
}

function getAttributeOptions(attributes: CharacterAttribute[] | null | undefined): Array<{
	value: string
	label: string
}> {
	if (!attributes) return []
	const options: Array<{ value: string; label: string }> = []
	attributes.forEach((attribute) => {
		options.push({
			value: attribute._id,
			label: attribute.name,
		})
	})
	return options
}

export function CharacterAttributesInput({
	value,
	onChange,
	characterId,
}: {
	value: string[]
	onChange: (arg0: string[]) => unknown
	characterId: string | null | undefined
}): JSX.Element {
	const character = useSelector((state: ReduxStore) => getCharacterById(state, characterId))
	const options = getAttributeOptions(character?.attributes)
	const selectValues = options.filter((option) => value.includes(option.value))
	useEffect(() => {
		if (character) {
			value.forEach((attId, index) => {
				if (!character.attributes.some((item) => item._id === attId)) {
					const changed = value.slice()
					changed.splice(index, 1)
					onChange(changed)
				}
			})
		}
	}, [character, value, onChange])
	return (
		<Select
			value={selectValues}
			onChange={(selectedVals) => {
				onChange(selectedVals.map((option) => option.value))
			}}
			isMulti
			closeMenuOnSelect={false}
			options={options}
		/>
	)
}
const Fields = {
	onComplete: 'onComplete',
	duration: 'duration',
	healthPerRound: 'healthPerRound',
	mapId: 'mapId',
	mapObjectId: 'mapObjectId',
	hitsPerStudent: 'hitsPerStudent',
	targetType: 'targetType',
	connectionsPerStudent: 'connectionsPerStudent',
	max: 'max',
	min: 'min',
	targetPower: 'targetPower',
	scansPerStudent: 'scansPerStudent',
	objectName: 'objectName',
	objectDescription: 'objectDescription',
	targets: 'targets',
}
const juniorCommonFields = [Fields.onComplete, Fields.objectName, Fields.objectDescription]
const plusCommonFields = [Fields.onComplete, Fields.mapId, Fields.mapObjectId]
const PLUS_STATION_DATA_FIELDS: Record<string, Set<string>> = {
	REPAIRS: new Set([Fields.onComplete, Fields.duration, Fields.healthPerRound]),
	// TODO: delete DEFENSE and TRACTOR_BEAM from PLUS_STATION_DATA_FIELDS after we run the script that converts them all to the PLUS version
	DEFENSE: new Set([...plusCommonFields, Fields.targetType, Fields.hitsPerStudent]),
	TRACTOR_BEAM: new Set([...plusCommonFields, Fields.targetType, Fields.hitsPerStudent]),
	DEFENSE_PLUS: new Set([Fields.onComplete, Fields.targets]),
	TRACTOR_BEAM_PLUS: new Set([Fields.onComplete, Fields.targets]),
	COMMUNICATION: new Set([...plusCommonFields, Fields.hitsPerStudent]),
	TRANSPORTER: new Set([...plusCommonFields, Fields.connectionsPerStudent]),
	POWER: new Set([...plusCommonFields, Fields.max, Fields.min]),
}
const JUNIOR_STATION_DATA_FIELDS: Record<string, Set<string>> = {
	REPAIRS: new Set([Fields.onComplete, Fields.duration, Fields.healthPerRound]),
	DEFENSE: new Set([...juniorCommonFields, Fields.targetType, Fields.hitsPerStudent]),
	TRACTOR_BEAM: new Set([...juniorCommonFields, Fields.targetType, Fields.hitsPerStudent]),
	COMMUNICATION: new Set([...juniorCommonFields, Fields.hitsPerStudent]),
	TRANSPORTER: new Set([...juniorCommonFields, Fields.connectionsPerStudent]),
	POWER: new Set([...juniorCommonFields, Fields.targetPower]),
	SCANNING: new Set([...juniorCommonFields, Fields.scansPerStudent]),
}

/**
 * These are limits we want to set on some properties of station data
 */
const NUMBER_LIMITS = {
	targetPower: {
		min: 0,
		max: 10,
	},
	max: {
		min: 0,
		max: 10,
	},
	min: {
		min: 0,
		max: 10,
	},
}

export function StationData({
	value,
	onChange,
	actions,
	actionId,
	onLeave,
}: {
	value: ActivateStationData<string>
	onChange: (
		e:
			| {
					type: 'STATION_DATA'
					stationData: ActivateStationData<string>
			  }
			| EventResultChangeProps
	) => void
	actions?: ReadonlyArray<Action<string> | ActionReference>
	actionId: ActionId
	onLeave: (arg0: (arg0: boolean) => void) => () => void
}): JSX.Element {
	const isChildOfMapObjectEvent: boolean = useSelector((state: ReduxStore) =>
		selectors.isChildOfMapObjectEvent(state, actionId)
	)
	const isJunior = useSelector(selectors.isJunior)
	const {
		stationId,
		// @ts-expect-error not every station has these fields
		_id,
		// @ts-expect-error not every station has these fields
		objectDescription,
		// @ts-expect-error not every station has these fields
		objectName,
		// @ts-expect-error not every station has these fields
		mapObjectId,
		// @ts-expect-error not every station has these fields
		mapId,
		// @ts-expect-error not every station has these fields
		targetType,
		// @ts-expect-error not every station has these fields
		duration,
		// @ts-expect-error not every station has these fields
		targets,
		// @ts-expect-error not every station has these fields
		onComplete,

		...numberFields
	} = value
	const dataFields = isJunior
		? JUNIOR_STATION_DATA_FIELDS[stationId]
		: PLUS_STATION_DATA_FIELDS[stationId]
	return (
		<div>
			<FormComponentWrapper
				key="stationData_stationId"
				label="Station"
				component={
					<StationIdSelector
						value={stationId}
						onChange={(newValue: StationId) => {
							const modifiedStationData = isJunior
								? handleJrStationChange(
										value as JrActivateStationData<string>,
										newValue as JrStationId
								  )
								: handleJrPlusStationChange(
										value as JrPlusActivateStationData<string>,
										newValue as JrPlusStationId
								  )
							onChange({
								type: 'STATION_DATA',
								stationData: modifiedStationData,
							})
						}}
					/>
				}
			/>
			{value.stationId === SHARED_STATION_IDS.CREATIVE_CANVAS ? (
				<CreativeCanvasStationDataEditor
					{...{
						stationData: value,
						onChange: (stationData) => {
							onChange({
								type: 'STATION_DATA',
								stationData,
							})
						},
						onEventResultChange: onChange,
						actionId,
						onLeave,
						actions: actions || [],
					}}
				/>
			) : (
				<>
					{dataFields.has(Fields.onComplete) && (
						<FormComponentWrapper
							key="stationData_onComplete"
							label="On Complete"
							component={
								<EventResultInput
									label="stationData"
									actionId={actionId}
									value={value.onComplete}
									onChange={(e) => onChange(e)}
									actions={actions}
									onLeave={onLeave}
								/>
							}
						/>
					)}
					{dataFields.has(Fields.mapId) && dataFields.has(Fields.mapObjectId) && (
						<>
							{isChildOfMapObjectEvent && (
								<ExtraMessage>
									Editing is disabled for target because this station action is connected to a map
									object event
								</ExtraMessage>
							)}
							<FormComponentWrapper
								key="stationData_mapId"
								label="Target's map"
								component={
									<MapSelector
										value={mapId}
										disabled={isChildOfMapObjectEvent}
										onChange={(newVal) => {
											const newMapObjectId = newVal === mapId ? mapObjectId : null
											onChange({
												type: 'STATION_DATA',
												// @ts-expect-error We know this station is using a mapId. That's why the MapSelector is shown in the first place
												stationData: { ...value, mapId: newVal, mapObjectId: newMapObjectId },
											})
										}}
									/>
								}
							/>
							<FormComponentWrapper
								key="stationData_mapObjectId"
								label="Target object"
								component={
									<MapObjectSelector
										value={mapObjectId || ''}
										disabled={isChildOfMapObjectEvent}
										mapId={mapId || ''}
										onChange={(newVal) =>
											onChange({
												type: 'STATION_DATA',
												// @ts-expect-error We know this station is using a mapObjectId. That's why the MapObjectSelector is shown in the first place
												stationData: { ...value, mapObjectId: newVal },
											})
										}
									/>
								}
							/>
						</>
					)}
					{((dataFields.has(Fields.objectDescription) && dataFields.has(Fields.objectName)) ||
						(dataFields.has(Fields.mapObjectId) && !mapObjectId)) && (
						<>
							{!isJunior && (
								<ExtraMessage>
									Use object name and description if you do not wish to connect this action to a map
									object
								</ExtraMessage>
							)}
							<FormComponentWrapper
								key="stationData_objectName"
								label="Object name"
								component={
									<StringInput
										value={objectName || ''}
										onChange={(newVal) =>
											onChange({
												type: 'STATION_DATA',
												// @ts-expect-error We know this station is using a objectName. That's why this input is shown in the first place
												stationData: { ...value, objectName: newVal },
											})
										}
									/>
								}
							/>
							<FormComponentWrapper
								key="stationData_objectDescription"
								label="Object description"
								component={
									<StringInput
										value={objectDescription || ''}
										onChange={(newVal) =>
											onChange({
												type: 'STATION_DATA',
												// @ts-expect-error We expect this station to need `objectDescription`
												stationData: { ...value, objectDescription: newVal },
											})
										}
									/>
								}
							/>
						</>
					)}
					{dataFields.has(Fields.targetType) && !mapId && (
						<FormComponentWrapper
							key="stationData_targetType"
							label="Target type"
							component={
								<TargetTypeSelector
									value={targetType || TARGET_TYPE.TARGET}
									onChange={(newTargetType) => {
										onChange({
											type: 'STATION_DATA',
											// @ts-expect-error we know targetType is allowed
											stationData: { ...value, targetType: newTargetType },
										})
									}}
								/>
							}
						/>
					)}
					{dataFields.has(Fields.duration) && (
						<FormComponentWrapper
							key="stationData_duration"
							label="Station duration"
							component={
								<TimeInput
									value={duration || 0}
									onChange={(newDuration) => {
										onChange({
											type: 'STATION_DATA',
											// @ts-expect-error we know duration is allowed
											stationData: { ...value, duration: newDuration },
										})
									}}
								/>
							}
						/>
					)}
					{dataFields.has(Fields.targets) && (
						<FormComponentWrapper
							label="Targets"
							component={
								value.stationId === JR_PLUS_STATION_IDS.DEFENSE_PLUS ? (
									<DefenseTargetsEditor
										targets={value.targets}
										onChange={(newTargets) => {
											onChange({
												type: 'STATION_DATA',
												stationData: { ...value, targets: newTargets },
											})
										}}
									/>
								) : value.stationId === JR_PLUS_STATION_IDS.TRACTOR_BEAM_PLUS ? (
									<TractorBeamTargetsEditor
										targets={value.targets}
										onChange={(newTargets) => {
											onChange({
												type: 'STATION_DATA',
												stationData: { ...value, targets: newTargets },
											})
										}}
									/>
								) : (
									<p>{value.stationId} does not support `targets`. Talk to the dev team</p>
								)
							}
						/>
					)}

					{Object.keys(numberFields).map((key) => {
						// @ts-expect-error we know key will be inside object
						const num = value[key] as number
						let max
						let min

						if (key in NUMBER_LIMITS) {
							// @ts-expect-error we check if NUMBER_LIMITS keys includes the current key
							const limits = NUMBER_LIMITS[key]
							max = limits.max
							min = limits.min
						}

						return (
							<FormComponentWrapper
								key={`stationData_${key}`}
								label={startCase(key)}
								component={
									<NumberInput
										value={num}
										onChange={(newVal) =>
											onChange({
												type: 'STATION_DATA',
												stationData: { ...value, [key]: newVal },
											})
										}
										max={max}
										min={min}
									/>
								}
							/>
						)
					})}
				</>
			)}
		</div>
	)
}

/**
 * A select component to select a question id from questions in the QUESTIONS redux form
 * store.
 * {props.value} The current value. Either a question id or the empty string
 * {props.onChange} Called with the newly selected question id
 */
export function QuestionSelector({
	value,
	onChange,
}: {
	value: string
	onChange: (arg0: string) => unknown
}): JSX.Element {
	const questions = useSelector(selectors.getQuestions)
	return (
		<div
			css={{
				display: 'flex',
				alignItems: 'center',
			}}>
			<Input
				type="select"
				value={value}
				onChange={(e) => {
					onChange(e.currentTarget.value)
				}}>
				{value === '' && <option>Select Question</option>}
				{questions
					.filter((question) => question._id)
					.map((question, index) => (
						<option key={question._id} value={question._id}>
							{question.type === variantTypes.CLOZE_DROPDOWN
								? `Q${index + 1}: Cloze dropdown`
								: question.type === variantTypes.IMAGE_LABELING
								? `Q${index + 1}: Image labeling`
								: question.phrase}
						</option>
					))}
			</Input>
			<MdInfoOutline
				title="Only saved questions are shown. If a question does not appear in the list, click Save and check again"
				size={22}
				css={{
					marginLeft: '16px',
				}}
			/>
		</div>
	)
}

/**
 * A select component where the options are all the culminating moments actions in the editor simulation.
 */
export function CulminatingMomentSelector({
	value,
	onChange,
}: {
	value: string
	onChange: (e: string) => void
}): JSX.Element {
	const culminatingMoments = useEditorSimulationActionsByType('CULMINATING_MOMENT_SCREEN')

	return (
		<Input
			type="select"
			value={value}
			onChange={(e) => {
				onChange(e.currentTarget.value)
			}}>
			{!value && <option>Select Culminating Moment</option>}
			{culminatingMoments.map((culminatingMoment) => (
				<option key={culminatingMoment._id} value={culminatingMoment._id}>
					{culminatingMoment.questionInfo.phrase}
				</option>
			))}
		</Input>
	)
}
