import type { Standard, StandardSet } from '../../types/AutomatedSimulation'
import type { UseMutationResult, UseQueryResult } from 'react-query'

import { config } from '../../config'
import { ONE_MINUTE } from '../../helpers/constants'
import { AUTOMATED_SIMULATIONS_QUERY_KEYS } from '../automatedSimulations/queries'

import axios from 'axios'
import { keyBy } from 'lodash'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { IdMap } from '../../types/util'

/**
 * A hook for fetching all standard sets from the API.
 */
export function useStandardSets(): UseQueryResult<IdMap<StandardSet>> {
	return useQuery(
		'standardSets',
		async () => {
			const result = await axios.get(`${config.simulationsApiUrl}/api/standardSets`)

			return keyBy(result.data.standardSets, '_id')
		},
		{
			staleTime: 5 * ONE_MINUTE,
		}
	)
}

const STANDARDS_QUERY_KEYS = {
	all: ['standards'],
	byStandardSetId: (standardSetId: string) => [...STANDARDS_QUERY_KEYS.all, { standardSetId }],
}

/**
 * A hook for fetching all standards from the API.
 */
export function useStandards({
	standardSetId,
	...options
}: {
	standardSetId?: string
	enabled?: boolean
} = {}): UseQueryResult<Array<Standard<string>>> {
	return useQuery({
		queryKey: standardSetId
			? STANDARDS_QUERY_KEYS.byStandardSetId(standardSetId)
			: STANDARDS_QUERY_KEYS.all,
		staleTime: 5 * ONE_MINUTE,
		queryFn: async () => {
			const result = await axios.get(`${config.simulationsApiUrl}/api/standards`, {
				params: { standardSetIds: standardSetId ? [standardSetId] : undefined },
			})

			return result.data.standards
		},
		...options,
	})
}

/**
 * A hook that returns the UseMutationResult for deleting a standard.
 */
export function useDeleteStandard(): UseMutationResult<
	string,
	unknown,
	string,
	{
		previousStandards: Standard<string>[] | void
	}
> {
	const queryClient = useQueryClient()

	return useMutation(
		async (deletedStandardId: string): Promise<string> => {
			const result = await axios
				.delete(`${config.simulationsApiUrl}/api/standards/${deletedStandardId}`)
				.catch((e) => {
					throw new Error(e.response.data.message)
				})
			return result.data.id
		},
		{
			// cancel any outgoing refetches (so they don't overwrite our optimistic update)
			onMutate: (deletedId) => {
				queryClient.cancelQueries(STANDARDS_QUERY_KEYS.all)
				// snapshot the previous value
				const previousStandards = queryClient.getQueryData<Standard<string>[]>(
					STANDARDS_QUERY_KEYS.all
				)
				// optimistically update and remove the standard
				if (previousStandards) {
					queryClient.setQueryData<Standard<string>[]>(
						STANDARDS_QUERY_KEYS.all,
						previousStandards.filter((standard) => standard._id !== deletedId)
					)
				}
				return { previousStandards }
			},
			// invalidate queries for standards and simulations since the standard will have been removed from both places
			onSuccess: (deletedStandardId: string) => {
				queryClient.invalidateQueries(STANDARDS_QUERY_KEYS.all)
				queryClient.invalidateQueries(AUTOMATED_SIMULATIONS_QUERY_KEYS.all)
			},
			onError: (_, __, context) => {
				// roll back to the previous value
				if (context?.previousStandards) {
					queryClient.setQueryData<Standard<string>[]>(
						STANDARDS_QUERY_KEYS.all,
						context.previousStandards
					)
				}
			},
		}
	)
}

/**
 * A hook that returns the UseMutationResult for updating a standard.
 */
export function useUpdateStandard(): UseMutationResult<
	Standard<string>,
	unknown,
	Standard<string>,
	{
		previousStandards: Standard<string>[] | void
	}
> {
	const queryClient = useQueryClient()

	return useMutation(
		async (standard: Standard<string>): Promise<Standard<string>> => {
			const result = await axios
				.put(`${config.simulationsApiUrl}/api/standards/${standard._id}`, standard)
				.catch((e) => {
					throw new Error(e.response.data.message)
				})

			return result.data.standard
		},
		{
			onMutate: (updatedStandard: Standard<string>) => {
				// cancel any outgoing refetches (so they don't overwrite our optimistic update)
				queryClient.cancelQueries(STANDARDS_QUERY_KEYS.all)

				// snapshot the previous value
				const previousStandards = queryClient.getQueryData<Standard<string>[]>(
					STANDARDS_QUERY_KEYS.all
				)

				// optimistically update to the new value
				if (previousStandards) {
					queryClient.setQueryData<Standard<string>[]>(
						STANDARDS_QUERY_KEYS.all,
						previousStandards.map((standard) =>
							standard._id === updatedStandard._id ? updatedStandard : standard
						)
					)
				}

				// return a context object with the snapshotted value
				return { previousStandards }
			},
			onSuccess: (updatedStandard: Standard<string>) => {
				queryClient.invalidateQueries(STANDARDS_QUERY_KEYS.all)
				queryClient.invalidateQueries(AUTOMATED_SIMULATIONS_QUERY_KEYS.all)
			},
			onError: (_, __, context) => {
				// roll back to the previous value
				if (context?.previousStandards) {
					queryClient.setQueryData<Standard<string>[]>(
						STANDARDS_QUERY_KEYS.all,
						context.previousStandards
					)
				}
			},
		}
	)
}

/**
 * A hook that returns the UseMutationResult for creating a standard.
 */
export function useCreateStandard(): UseMutationResult<
	Standard<string>,
	unknown,
	Standard<string>
> {
	const queryClient = useQueryClient()

	return useMutation(
		async ({ _id, ...standard }: Standard<string>): Promise<Standard<string>> => {
			const result = await axios
				.post(`${config.simulationsApiUrl}/api/standards`, standard)
				.catch((e) => {
					throw new Error(e.response.data.message)
				})

			return result.data.standard
		},
		{
			onSettled: () => {
				queryClient.invalidateQueries(STANDARDS_QUERY_KEYS.all)
			},
		}
	)
}
