import React, { useState, useCallback, useRef, useEffect } from 'react'
import { Container, Input } from 'reactstrap'
import AutomatedSimulationList from './AutomatedSimulationList'

/**
 * A component that displays all Archived Automated Simulations.
 * This component allows searching and restoring these simulations.
 */
export default function Archive(): JSX.Element {
	const [simulationFilter, setSimulationFilter] = useState('')

	/**
	 * A filter function that returns whether a simulation should be shown in the list
	 */
	const filter = useCallback(
		(simulation: { name: string }) =>
			simulation.name.toLowerCase().includes(simulationFilter.toLowerCase()),
		[simulationFilter]
	)
	const inputRef = useRef<HTMLInputElement>()
	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus()
		}
	}, [])
	return (
		<Container>
			<Input
				// @ts-expect-error the types for innerRef in reactstrap do not allow this perfectly valid ref
				innerRef={inputRef}
				placeholder="Search Simulations"
				value={simulationFilter}
				onChange={(e) => setSimulationFilter(e.currentTarget.value)}
			/>

			<AutomatedSimulationList filter={filter} archived={true} />
		</Container>
	)
}
