import type { ScreenActionId } from '../features/automatedSimulations/actionDefinitions'
import type { SimulationMissionGuideType } from '@mission.io/mission-guide'
import type { FullMap } from './MapTypes'
import type { ClientQuestion as Question } from '@mission.io/question-toolkit'
import { LeaderboardStatus } from '@mission.io/mission-toolkit'
import type { ActionMap, EventResult } from '@mission.io/mission-toolkit/actions'

export const MapObjectResultTypes = {
	onTarget: 'onTarget',
	onScan: 'onScan',
	onTransport: 'onTransport',
	onTractorBeam: 'onTractorBeam',
	onPower: 'onPower',
	onCommunication: 'onCommunication',
}

export type MapObjectResultType = keyof typeof MapObjectResultTypes

export type MapObjectResults = { [P in MapObjectResultType]?: EventResult<string> }

export const TEAM_TYPES: {
	COMMAND: 'COMMAND'
	ENGINEERING: 'ENGINEERING'
	MEDICAL: 'MEDICAL'
	SECURITY: 'SECURITY'
	IT: 'IT'
	OPERATIONS: 'OPERATIONS'
	SCIENCE: 'SCIENCE'
	FLIGHT_DIRECTOR: 'FLIGHT_DIRECTOR'
} = {
	COMMAND: 'COMMAND',
	ENGINEERING: 'ENGINEERING',
	MEDICAL: 'MEDICAL',
	SECURITY: 'SECURITY',
	IT: 'IT',
	OPERATIONS: 'OPERATIONS',
	SCIENCE: 'SCIENCE',
	FLIGHT_DIRECTOR: 'FLIGHT_DIRECTOR',
}
export type TeamType = keyof typeof TEAM_TYPES
export type MapEventResult = {
	onAllObjectsScanned: EventResult<string>
	objects: Record<string, MapObjectResults>
}

export enum StandardScope {
	NATIONAL = 'NATIONAL',
	STATE_SPECIFIC = 'STATE_SPECIFIC',
}

export type StandardSet = {
	_id: string
	name: string
	internalName: string
	abbreviation: string
	country: string
	color: string
	states: Array<{
		name: string
		abbreviation: string
		_id: string
	}>
	scope: StandardScope
	subject: {
		name: string
		_id: string
	}
}

export type Standard<StandardSetParam = StandardSet> = {
	_id: string
	standardSet: StandardSetParam
	name: string
	keyword: string
	summary: string
	full: string
	url: string
	grade: string
}

export type StationQuestions = Record<
	string,
	{
		questionIds: string[]
	}
>
export type ControlSet = 'K-3' | '4+'
export type BasicAutomatedSimulation = {
	_id: string
	name: string
	standards: Array<Standard>
	controlSet: ControlSet
}
export type SimulationTeam = {
	_id: string
	name: string
	engineeringPanelComponentType: TeamType
	icon: string
	required: boolean
	// required teams will fill to max before adding a student to a none-required team
	maxStudents: number
	// if necessary to go over, will add proportionally to their max student (ie. a min maxStudent will gain students at a slower rate a higher maxStudent team)
	minStudents: number // automatic filling
}
export type AutomatedSimulation = BasicAutomatedSimulation & {
	tag?: string
	subjectMatter?: string
	summary: string
	grades: string[]
	categories: string[]
	generatesAnalytics: boolean
	narration: string
	questions: Question[]
	imageUrl: string | null | undefined
	imageThumbnailUrl: string | null | undefined
	mapIds: string[]
	walkthroughVideoUrl: string | null | undefined
	briefingVideoUrl: string | null | undefined
	briefing: string
	leaderboardStatus: LeaderboardStatus
	prerequisiteKnowledge: string
	crossCurricularConnections: string
	suggestedVocabulary: Array<{
		word: string
		definition: string
	}>
	status: 'UNAVAILABLE' | 'COMING_SOON' | 'NEW' | 'ACTIVE'
	initialScreenActionId: ScreenActionId | null | undefined
	actions: ActionMap<string>
	mapEventResults: Record<string, MapEventResult>
	maxQuestions: number
	missionGuide: SimulationMissionGuideType
	maps: FullMap[]
	stationQuestions?: StationQuestions
	teams: SimulationTeam[]
	science3D: {
		phenomenon: string
		scienceAndEngineeringPractices: Array<{
			practiceText: string
			description: string
		}>
		crosscuttingConcepts: Array<{
			conceptText: string
			description: string
		}>
		disciplinaryCoreIdeas: Array<{
			ideaText: string
			description: string
		}>
	}
	archived: boolean
}
