import React, { ReactNode, useState } from 'react'
import { Link } from 'react-router-dom'
import {
	Button,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Input,
	Label,
	FormGroup,
} from 'reactstrap'
import classNames from 'classnames'
import { HiOutlineDuplicate as DuplicateIcon, HiArchive } from 'react-icons/hi'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { toast as helperToast } from '../../helpers/uiFunctions'
import type { BasicAutomatedSimulation } from '../../types/AutomatedSimulation'
import {
	useDuplicateSimulation,
	useUpdateAutomatedSimulation,
} from '../automatedSimulations/queries'

// The Card component that the simulation is displayed in.
export function Card({ children }: { children: ReactNode }): JSX.Element {
	return <div className="bg-light rounded shadow p-3 mb-3">{children}</div>
}

// The header component for the simulation card, takes text as a prop: this text should be the title of the simulation.
export function CardHeader({
	children,
	text,
}: {
	children?: ReactNode
	text: string
}): JSX.Element {
	return (
		<div className="border-bottom items-center" css="display: flex;">
			<h5 className="text-lg font-bold">{text}</h5>
			<div css="margin-left: auto;">{children}</div>
		</div>
	)
}

// The body component. takes data as a prop. The data is an array of tuples describing different fields on simulation that we'd like to display.
export function CardBody({
	children,
	data,
}: {
	children?: ReactNode
	data: Array<[string, ReactNode]>
}): JSX.Element {
	return (
		<div>
			{data.map(([title, value]) => (
				<React.Fragment key={title}>
					<b>{title}:</b> {value}
					<br />
				</React.Fragment>
			))}
			{children}
		</div>
	)
}

// The Card Footer which contains a link to edit the given simulation id
export function CardFooter({ id }: { id: string }): JSX.Element {
	return (
		<div>
			<Link to={`/${id}`} className="btn btn-secondary btn-sm">
				Edit
			</Link>
		</div>
	)
}

/**
 * A button that restores a simulation (sets archived to false) when clicked.
 * @param {{name: string, _id: string}} simulation The simulation to restore
 * @param {string} className a class name for the button
 * @returns
 */
export function RestoreButton({
	simulation: { _id: id, name, controlSet },
	className,
}: {
	simulation: BasicAutomatedSimulation
	className?: string
}): JSX.Element {
	const { mutateAsync: updateSimulation } = useUpdateAutomatedSimulation()

	const restoreSimulation = () => {
		updateSimulation(
			{ simulationId: id, simulationData: { archived: false } },
			{
				onSuccess: () => {
					toast.success(`${name} successfully restored`)
				},
				onError: (error) => {
					if (error instanceof Error) {
						console.error(error)
						helperToast.error(
							error.message || 'There was an unexpected error restoring the simulation'
						)
						return
					}
					helperToast.error('Unable to restore the simulation.', error.general)
				},
			}
		)
	}
	return (
		<Button
			color="primary"
			className={classNames(className, 'py-1 px-2 m-1')}
			onClick={restoreSimulation}>
			Restore
		</Button>
	)
}

/**
 * A component that renders a button that archives a simulation. When clicked, it opens a modal which confirms that the user would like to archive
 * the simulation. When the user clicks "Archive", it archives the simulation.
 * @param {{name: string, _id: string}} simulation The simulation to archive
 * @param {string} className a class name for the button
 * @returns
 */
export function ArchiveButton({
	simulation: { _id: id, name, controlSet },
	className,
}: {
	simulation: BasicAutomatedSimulation
	className?: string
}): JSX.Element {
	const [modalIsOpen, setModalIsOpen] = useState(false)

	const { mutateAsync: updateSimulation, isLoading } = useUpdateAutomatedSimulation()

	const onClose = () => {
		setModalIsOpen(false)
	}

	const archiveSimulation = () => {
		updateSimulation(
			{ simulationId: id, simulationData: { archived: true }, mustUpdateStore: false },
			{
				onSuccess: () => {
					toast.success(`${name} successfully archived`)
				},
				onError: (error) => {
					if (error instanceof Error) {
						console.error(error)
						helperToast.error(
							error.message || 'There was an unexpected error archiving the simulation'
						)
						return
					}
					helperToast.error('Unable to archive the simulation.', error.general)
				},
			}
		).finally(() => {
			onClose()
		})
	}

	return (
		<>
			{modalIsOpen && (
				<Modal isOpen={true}>
					<ModalHeader toggle={onClose}>Archive {name}?</ModalHeader>
					<ModalBody>This simulation can be restored from the Archive.</ModalBody>
					<ModalFooter>
						<Button onClick={onClose}>Cancel</Button>
						<Button onClick={archiveSimulation} color="primary">
							Archive
						</Button>
					</ModalFooter>
				</Modal>
			)}
			<Button
				disabled={isLoading}
				color="link"
				className={className}
				onClick={() => setModalIsOpen(true)}>
				<HiArchive size={22} />
			</Button>
		</>
	)
}

/**
 A component that renders a button that duplicates a simulation. When clicked, it opens a modal which asks for the name of the new simulation. 
 When the user clicks "Duplicate", it duplicates the simulation and redirects to the new simulation.
 * @param {{name: string, _id: string}} simulation The simulation to duplicate 
 */
export function DuplicateButton({
	simulation: { _id: id, name, controlSet },
	className,
}: {
	simulation: BasicAutomatedSimulation
	className?: string
}): JSX.Element {
	const history = useHistory()
	const [newSimulationName, setNewSimulationName] = useState(name + ' (Copy)')
	const [doControlSetTransform, setDoControlSetTransform] = useState(false)
	const { mutateAsync: duplicateSimulation, isLoading: isDuplicating } = useDuplicateSimulation()
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const onClose = () => {
		setModalIsOpen(false)
	}
	return (
		<>
			{modalIsOpen && (
				<Modal isOpen={true}>
					<ModalHeader toggle={onClose}>Duplicate Simulation</ModalHeader>
					<ModalBody>
						Name of Copy
						<Input
							value={newSimulationName}
							onChange={(e) => setNewSimulationName(e.currentTarget.value)}
						/>
						{/* Only allow control set transform from standard to junior missions as of now. TODO support from junior to standard  */}
						{controlSet === '4+' && (
							<FormGroup check css="margin-top: 1rem;">
								<Input
									type="checkbox"
									id="transform-controlSet"
									checked={doControlSetTransform}
									onChange={(e) => setDoControlSetTransform((state) => !state)}
								/>
								<Label check>Switch control set to “Junior“</Label>
							</FormGroup>
						)}
					</ModalBody>
					<ModalFooter>
						<Button
							onClick={() => {
								duplicateSimulation(
									{
										name: newSimulationName,
										id,
										transformTo: doControlSetTransform
											? controlSet === 'K-3'
												? '4+'
												: 'K-3'
											: undefined,
									},
									{
										onError: (error) => {
											toast.error(`${error instanceof Error ? error.message : ''}.`)
										},
									}
								).then((simulationId) => {
									onClose()
									history.push(`/${simulationId}`)
								})
							}}
							color="primary"
							disabled={isDuplicating}>
							Duplicate
						</Button>
					</ModalFooter>
				</Modal>
			)}
			<Button color="link" className={className} onClick={() => setModalIsOpen(true)}>
				<DuplicateIcon size={24} />
			</Button>
		</>
	)
}
