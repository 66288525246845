import type { Map, MapObject as MapObjectFromMissionToolkit } from '@mission.io/mission-toolkit'
type LifeFormHolder = {
	lifeforms: number
}
export type MapIcon = {
	url: string
	width: number
	height: number
}
export type DataType = 'VIDEO' | 'AUDIO' | 'IMAGE' | 'TEXT'
export type MapDataContentType = {
	_id: string
	type: DataType
	text: string
	url?: string
	relevance: number
}
export type ObjectContentType = MapDataContentType & {
	prescanned?: boolean
}
type GeneralMapObject = MapObjectFromMissionToolkit
export type PlanetType = 'PLANET' | 'ASTEROID' | 'STAR'
export type CelestialBody = LifeFormHolder &
	GeneralMapObject & {
		type: PlanetType
	}
export type ShipType = 'FRIENDLY' | 'FOE' | 'NEUTRAL'
export type Ship = LifeFormHolder &
	GeneralMapObject & {
		type: ShipType
	}
export type ContactType = 'FRIENDLY' | 'FOE' | 'UNKNOWN'
export type Contact = GeneralMapObject & {
	type: ContactType
}
export type OtherObject = GeneralMapObject & {
	icon: MapIcon
}
export type MapObject = CelestialBody | Ship | OtherObject | Contact
type Self = {
	location: {
		x: number
		y: number
	}
}
export type MapObjects = {
	self: Self
	ships: Ship[]
	celestialBodies: CelestialBody[]
	other: OtherObject[]
	contacts: Contact[]
}
export type NormalizedObjects = {
	self: Self
	ships: string[]
	celestialBodies: string[]
	other: string[]
	contacts: string[]
}
export type MapAttributes = {
	_id: string
	name: string
	description: string
	color: string | null | undefined
	backgroundImage: string | null | undefined
	data: MapDataContentType[]
}

/**
 * A map where the `objects` field contains the full objects.
 */
export type FullMap = Map & {
	objects: MapObjects
}

/**
 * A map where the `objects` field only contains the ids of the objects.
 */
export type NormalizedMap = MapAttributes & {
	objects: NormalizedObjects
}
export const allMapObjectClassifications: Record<
	'celestialBodies' | 'ships' | 'contacts',
	{
		[objectType in ShipType | PlanetType | ContactType]?: {
			displayName: string
			id: ShipType | PlanetType | ContactType
		}
	}
> = {
	celestialBodies: {
		PLANET: {
			displayName: 'Planet',
			id: 'PLANET',
		},
		ASTEROID: {
			displayName: 'Asteroid',
			id: 'ASTEROID',
		},
		STAR: {
			displayName: 'Star',
			id: 'STAR',
		},
	},
	ships: {
		FRIENDLY: {
			displayName: 'Friendly',
			id: 'FRIENDLY',
		},
		FOE: {
			displayName: 'Foe',
			id: 'FOE',
		},
		NEUTRAL: {
			displayName: 'Neutral',
			id: 'NEUTRAL',
		},
	},
	contacts: {
		FRIENDLY: {
			displayName: 'Friendly',
			id: 'FRIENDLY',
		},
		FOE: {
			displayName: 'Foe',
			id: 'FOE',
		},
		UNKNOWN: {
			displayName: 'Unknown',
			id: 'UNKNOWN',
		},
	},
}
export type MapObjectType = 'CELESTIAL_BODY' | 'SHIP' | 'OTHER' | 'CONTACT'
