import React, { useState } from 'react'

import './StandardList.css'
import { Button } from 'reactstrap'
import Select from 'react-select'

import type { Standard, Standard as StandardType } from '../types/AutomatedSimulation'
import { StandardDisplay } from '../features/standards/StandardDisplay'
import { useStandardSets, useStandards } from '../features/standards/hooks'
import { toast } from 'react-toastify'
import { FieldArrayFieldsProps } from 'redux-form'
import classNames from 'classnames'

/**
 * A component to be used as the `component` for a redux-form `FieldArray`
 */
export function FieldsStandardList({
	fields: { length, push, map },
}: {
	fields: FieldArrayFieldsProps<StandardType>
	meta: { form: string }
}): JSX.Element {
	const [standardsToAdd, setStandardsToAdd] = useState<Array<Standard<string>>>([])
	const { data: standardSets } = useStandardSets()

	const existingStandardIds = new Set(map((_, i, { get }) => get(i)._id))

	return (
		<div className="StandardList">
			{length === 0 ? (
				<p className="my-1">
					<i>No standards found</i>
				</p>
			) : (
				map((fieldName, index, { remove, name, get }) => ({
					standard: get(index),
					remove: () => {
						remove(index)
					},
				}))
					.sort(({ standard: standard1 }, { standard: standard2 }) =>
						standard1.standardSet.abbreviation.localeCompare(standard2.standardSet.abbreviation)
					)
					.map(({ standard, remove }) => (
						<StandardDisplay key={standard._id} onDelete={() => remove()} standard={standard} />
					))
			)}
			<div className="flex-col flex gap-2 md:flex-row md:items-end">
				<StandardSelector
					value={standardsToAdd}
					onChange={setStandardsToAdd}
					standardsToExclude={existingStandardIds}
					className="flex-grow"
				/>
				<Button
					onClick={() => {
						let standardsAdded = 0
						standardsToAdd.forEach((standard) => {
							const standardSet = standardSets?.[standard?.standardSet || '']
							if (!standard || !standardSet) {
								return
							}
							push({ ...standard, standardSet })
							standardsAdded++
						})

						if (standardsAdded === 0) {
							toast.warn('Please select at least one valid standard')
						} else if (standardsAdded !== standardsToAdd.length) {
							toast.warn('Some standards were not added because they were invalid')
						}

						setStandardsToAdd([])
					}}>
					Add Standards
				</Button>
			</div>
		</div>
	)
}

/**
 * Controlled component to select one or many standards. user must select a standard set first,
 * then standards within that set.
 * @param value The id of the currently selected standard
 * @param onChange A callback to be called when the selected standard changes
 * @param standardsToExclude A set of standard ids to exclude from the list. Any standard with an id in this set will not be shown.
 * @param className An optional class name to apply to the wrapper div of the select components
 */
function StandardSelector({
	value,
	onChange,
	standardsToExclude,
	className,
}: {
	value: Array<Standard<string>>
	onChange: (newStandards: Array<Standard<string>>) => unknown
	standardsToExclude?: Set<string>
	className?: string
}) {
	const [selectedStandardSetId, setSelectedStandardSetId] = useState<string | undefined>(undefined)
	const { data: standards, isLoading: loadingStandards } = useStandards({
		standardSetId: selectedStandardSetId,
		enabled: !!selectedStandardSetId,
	})
	const { data: standardSets } = useStandardSets()

	const values = value.map((standard) => {
		const standardSet = standardSets?.[standard.standardSet]?.abbreviation

		return {
			value: standard,
			label: `${standardSet} ${standard.name}`,
		}
	})

	return (
		<div className={classNames('flex-col flex gap-2', className)}>
			<Select
				value={
					selectedStandardSetId
						? {
								value: selectedStandardSetId,
								label:
									standardSets?.[selectedStandardSetId]?.abbreviation ||
									standardSets?.[selectedStandardSetId]?.internalName,
						  }
						: undefined
				}
				onChange={(option) => setSelectedStandardSetId(option?.value)}
				options={
					standardSets
						? Object.values(standardSets)?.map((standardSet) => ({
								value: standardSet._id,
								label: standardSet.abbreviation || standardSet.internalName,
						  }))
						: []
				}
				placeholder="Standard Set"
			/>
			<Select
				value={values}
				onChange={(options) => onChange(options.map((option) => option.value))}
				options={standards
					?.filter((standard) => !standardsToExclude?.has(standard._id))
					.map((standard) => ({
						value: standard,
						label: `${standardSets?.[standard.standardSet]?.abbreviation} ${standard.name}`,
					}))}
				closeMenuOnSelect={false}
				isMulti
				placeholder={selectedStandardSetId ? 'Standards' : 'Select a standard set...'}
				isDisabled={!selectedStandardSetId || loadingStandards}
			/>
		</div>
	)
}
