import React, { useState } from 'react'
import { DataEditor, MediaTypePicker } from './DataEditor'
import { Button } from 'reactstrap'
import type { ObjectContentType, MapDataContentType, DataType } from '../../../../../types'

/**
 * A react component which allows the user to edit the list of data on a map or map object.
 * @param data The data to edit
 * @param onChange A function that is called when the data list changes
 * @param includePrescannedField Whether to include the `prescanned` field in the data
 */
export function DataListEditor<T extends MapDataContentType | ObjectContentType>({
	onChange,
	data,
	includePrescannedField = true,
}: {
	onChange: (arg0: Array<T & { _id?: string }>) => void
	data: T[]
	includePrescannedField?: boolean
}): JSX.Element {
	const [typeChosen, setTypeChosen] = useState<DataType | null | undefined>(null)
	return (
		<>
			<div className="flex items-baseline justify-between">
				<h5>Media Information</h5>
				<div className="flex gap-4">
					<MediaTypePicker onSelect={setTypeChosen} mediaType={typeChosen} />
					<Button
						disabled={!typeChosen}
						onClick={() => {
							if (typeChosen) {
								const objectContent: Array<T & { _id?: string }> = [...data]
								const newDatum: ObjectContentType & { _id?: string } = {
									text: '',
									type: typeChosen,
									relevance: 0,
								} as ObjectContentType & { _id?: string }

								if (includePrescannedField) {
									newDatum.prescanned = false
								}

								// @ts-expect-error - The generics need to be fixed here
								objectContent.push(newDatum)
								onChange(objectContent)
							}
						}}
						color="primary">
						Add Media
					</Button>
				</div>
			</div>

			{data.map((media, index) => (
				<div key={`media-${index}`}>
					<hr />
					<DataEditor
						onChange={(updater) => {
							const objectContent = [...data]
							objectContent[index] = updater(objectContent[index])
							onChange(objectContent)
						}}
						onDelete={() => {
							const objectContent = [...data]
							objectContent.splice(index, 1)
							onChange(objectContent)
						}}
						data={media}
						includePrescannedField={includePrescannedField}
					/>
				</div>
			))}
		</>
	)
}
