import React from 'react'
import * as components from './FormPieces/FormComponents'
import { PLAYER_COLLISION_WITH_SOLID_DESCRIPTION } from '../helpers/constants'
import { MediaInput, getSelectInput } from './FormPieces/BasicFormComponents'
import type { Video, Image } from '@mission.io/mission-toolkit/actions'
import { COLLABORATIVE_CULMINATING_MOMENT } from '@mission.io/mission-toolkit/constants'

/*
 * This map contains all possible fields on an action to its associated form component.
 * This map also contains keys 'makePhase' and 'makeCheckpoint'. These are used to simplify the UI for the user.
 * If makePhase or makeCheckpoint is checked, then the newPhase or checkpoint property is added to the action.
 */
export const componentMap = {
	accessibilityVideoUrl: components.UrlInput,
	atTime: components.AtTimeInput,
	attributes: components.CharacterAttributesInput,
	audioUrl: components.UrlInput,
	canvasActionId: components.CreativeCanvasSelector,
	caption: components.LargeStringInput,
	characterId: components.CharacterSelector,
	checkpoint: components.StringInput,
	connectionsPerStudent: components.NumberInput,
	difference: components.NumberInput,
	display: components.VocalTrackDisplaySelector,
	duration: components.TimeInput,
	editingTime: components.TimeInput,
	effectId: components.MissionEffectInput,
	ending: getSelectInput(Object.keys(COLLABORATIVE_CULMINATING_MOMENT.ENDING)) as ReturnType<
		typeof getSelectInput
	>,
	hitsPerStudent: components.NumberInput,
	looping: components.BooleanInput,
	finalFrameDuration: components.TimeInput,
	makeCheckpoint: components.BooleanInput,
	makePhase: components.BooleanInput,
	map: components.NavigationMapSelector,
	mapId: components.MapSelector,
	mapObjectId: components.MapObjectSelector,
	newPhase: components.StringInput,
	objectiveText: components.StringInput,
	onCheatDeath: components.EventResultInput,
	onComplete: components.EventResultInput,
	onDeath: components.EventResultInput,
	onEnd: components.EventResultInput,
	onFailure: components.EventResultInput,
	onPlayerCollisionWithSolid: components.EventResultInputWithDescription(
		PLAYER_COLLISION_WITH_SOLID_DESCRIPTION
	) as ReturnType<typeof components.EventResultInputWithDescription>,
	onStart: components.EventResultInput,
	onVoteStart: components.EventResultInput,
	onSuccess: components.EventResultInput,
	questionInfo: components.QuestionInfoInput,
	result: components.EventResultInput,
	requiredPercentForSuccess: components.NumberInput,
	screenMedia(props: {
		value: Image | Video | null
		onChange: (value: Image | Video | null) => unknown
	}) {
		return (
			<MediaInput collection="automatedSimulationMedia" {...props} mediaType="VIDEO_OR_IMAGE" />
		)
	},
	shipHealth: components.NumberPercentageInput,
	softPause: components.BooleanInput,
	songUrl: components.SongUrlInput,
	specialEffectId: components.SpecialEffectInput,
	stationData: components.StationData,
	stationId: components.StationIdSelector,
	startTime: components.TimeInput,
	targetPower: components.NumberInput,
	teacherInfo: components.StringInput,
	text: components.StringInput,
	timeLimit: components.TimeInput,
	type: components.ScreenOverlaySelector,
	url: components.UrlInput,
	questionId: components.QuestionSelector,
	onContinue: components.EventResultInput,
	prompt: components.StringInput,
	mainScreenText: components.StringInput,
	isShipDeath: components.BooleanInput,
	allowGoBack: components.BooleanInput,
	isActive: components.BooleanInput,
	continueButtonText: components.StringInput,
	goBackButtonText: components.StringInput,

	// LITERACY EVENT INPUTS
	readingContexts: components.LiteracyEvent.ReadingContextEditor,
	assignment: components.LiteracyEvent.Assignment,
	dataAppearance: components.LiteracyEvent.DataAppearance,

	// TOGGLE_INVESTIGATION INPUTS
	status: getSelectInput(['ACTIVE', 'INACTIVE']),
	culminatingMomentActionId: components.CulminatingMomentSelector,
}
