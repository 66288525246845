import classNames from 'classnames'
import React from 'react'
import styled from 'styled-components'
export default function Spinner({
	headerClassName,
	spinnerClassName,
}: {
	headerClassName?: string
	spinnerClassName?: string
}): JSX.Element {
	return (
		<SpinnerHolder className={headerClassName}>
			<Spin className={classNames('spinner-border', spinnerClassName)} role="status">
				<span className="sr-only">Loading...</span>
			</Spin>
		</SpinnerHolder>
	)
}

const SpinnerHolder = styled.div`
	width: fit-content;
	margin: 30px auto 30px auto;
`
const Spin = styled.div`
	height: 4rem;
	width: 4rem;
`
