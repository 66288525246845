import React, { useState, useCallback, useLayoutEffect, Dispatch, SetStateAction } from 'react'
import { toast } from 'react-toastify'
import SimpleConfirmation from '../../../common/SimpleConfirmation'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useFormContext } from '../FormContext'
import { Button } from 'reactstrap'
import FontAwesome from 'react-fontawesome'
import { IoMdPlay } from 'react-icons/io'
import { prettifyTypeEnum } from '../../../helpers/functions'
import {
	updateActionAndAddActions,
	updateMapObjectResultsAndAddActions,
	deleteActionOnSimulation,
} from '../../../reducers/simulationEditor'
import ActionGraph from './ActionGraph'
import Form from './Form'
import {
	selectors,
	deleteObject as deleteObjectFromSimulation,
} from '../../../reducers/simulationEditor'
import type { MapObjectResults } from '../../../types/AutomatedSimulation'
import { useSaveContextUpdate } from '../EditorSimulationSaveContext'
import { handleError } from '../PhaseCreator/helpers/stateHelpers'
import ObjectResultsForm from './FormPieces/MapObjectEventResultsForm'
import { actions as tileActions } from '../../../setup/tiles'
import { getNextActionIdsFromAction } from '../helpers/algorithms'
import type { FormState } from './types'
import type { FormConfig } from '../FormContext'
import { runWithConfirmationMessage } from '../../../helpers/uiFunctions'
import { DELETE_CONFIRMATION_MESSAGE } from '../helpers/constants'
import { IdMap } from '../../../types/util'
import { ReduxStore } from '../../../types/ReduxStore'
import type { Action } from '@mission.io/mission-toolkit/actions'

function useFormState(
	actions: IdMap<Action<string>> | null | undefined,
	config: FormConfig | null | undefined
): {
	formState: FormState | null | undefined
	setFormState: Dispatch<SetStateAction<FormState>>
	saveRequired: boolean
} {
	const [formState, setFormState] = useState<FormState | null | undefined>(null)
	const isMapEventForm = config ? Boolean(config.objectId && config.mapId) : false
	const isGeneralForm = config ? Boolean(config.id && !config.graph && !config.objectId) : false
	const mapObjectResults: MapObjectResults | null | undefined = useSelector((state: ReduxStore) =>
		config?.mapId && config?.objectId
			? selectors.getMapObjectResults(state, config.mapId, config.objectId)
			: null
	)
	const originalAction: Action<string> | null | undefined = config ? actions?.[config.id] : null
	const reset = useCallback(() => {
		if (isMapEventForm) {
			setFormState({
				type: 'MAP_EVENT',
				createdActions: [],
				modifiedMapObjectResult: mapObjectResults,
			})
		} else if (isGeneralForm && originalAction) {
			setFormState({
				type: 'GENERAL',
				createdActions: [],
				modifiedAction: originalAction,
			})
		} else {
			setFormState(null)
		}
	}, [isMapEventForm, isGeneralForm, mapObjectResults, originalAction])
	useLayoutEffect(() => {
		reset()
	}, [reset])
	const setNonNullState = useCallback((update: ((arg0: FormState) => FormState) | FormState) => {
		setFormState((state) =>
			state ? (typeof update === 'function' ? update(state) : update) : state
		)
	}, [])
	const saveRequired =
		formState?.type === 'GENERAL'
			? JSON.stringify(formState.modifiedAction) !== JSON.stringify(originalAction)
			: formState?.type === 'MAP_EVENT'
			? JSON.stringify(formState.modifiedMapObjectResult) !== JSON.stringify(mapObjectResults)
			: false
	return {
		saveRequired,
		formState,
		setFormState: setNonNullState,
	}
}

export default function FormPopover({
	playAction,
}: {
	playAction: (arg0: string) => unknown
}): JSX.Element | null {
	const {
		currentActionId: formId,
		setCurrentActionId: setFormId,
		currentConfig: config,
		formStack,
		pop,
	} = useFormContext()
	const actions = useSelector(selectors.getActions)
	const dispatch = useDispatch()
	const onUpdate = useSaveContextUpdate()
	const { formState, setFormState, saveRequired } = useFormState(actions, config)

	const toggle = (isSaved: boolean) => {
		if (formId || config?.mapId) {
			if (!isSaved) {
				deleteExtraObjects()
			}

			setFormId(null)
		}
	}

	const deleteExtraObjects = () => {
		const actualAction = formId ? actions?.[formId] : null
		const modifiedAction = formState?.type === 'GENERAL' && formState.modifiedAction

		if (actualAction && modifiedAction) {
			if (
				modifiedAction.type === 'ADD_MAP_OBJECT' &&
				modifiedAction.mapId &&
				modifiedAction.mapObjectId
			) {
				const mapId = modifiedAction.mapId
				const objectId = modifiedAction.mapObjectId
				dispatch(
					tileActions.maps.deleteObject({
						mapId: mapId,
						objectId: objectId,
					})
				)
				dispatch(deleteObjectFromSimulation(objectId))
			}
		}
	}

	const onSave = () => {
		if (formState) {
			try {
				if (formState.type === 'GENERAL') {
					const { createdActions, modifiedAction } = formState
					dispatch(updateActionAndAddActions(modifiedAction, createdActions))
					onUpdate()
				} else if (
					formState.type === 'MAP_EVENT' &&
					formState?.modifiedMapObjectResult &&
					config?.mapId &&
					config?.objectId
				) {
					const { createdActions, modifiedMapObjectResult } = formState
					dispatch(
						updateMapObjectResultsAndAddActions(
							config.mapId,
							config.objectId,
							modifiedMapObjectResult,
							createdActions
						)
					)
					onUpdate()
				}
			} catch (err) {
				handleError(err)
			}
		}
	}

	const onDelete = () => {
		const action = formId ? actions?.[formId] : null

		if (action) {
			const showConfirmation = actions
				? getNextActionIdsFromAction(action._id, actions).length > 0
				: false

			const onConfirm = () => {
				try {
					dispatch(deleteActionOnSimulation(action._id))
					onUpdate()
					toggle(false)
				} catch (err) {
					handleError(err)
				}
			}

			if (showConfirmation) {
				runWithConfirmationMessage(DELETE_CONFIRMATION_MESSAGE, onConfirm)
			} else onConfirm()
		}
	}

	const onClickBack = (isSaved: boolean) => {
		if (!isSaved) {
			deleteExtraObjects()
		}

		pop()
	}

	/**
	 * If the action is unsaved, prompts the user to save or revert changes on the action before calling the provided callback.
	 * If the action is saved, calls the callback immediately. If the action is saved before running the callback, `true` is passed
	 * as an argument to `cb`. This also includes if the callback was run immediately with no prompt.
	 * If the callback is run and the action is not saved, `false` is passed as the argument to `cb`.
	 */
	const wrapInLeavingConfirmation = (cb: (arg0: boolean) => void): (() => void) => {
		return () => {
			if (saveRequired) {
				const toastId = toast.error(
					<SimpleConfirmation
						secondButton={true}
						message={`If you leave this form before confirming changes then all changes will be reverted`}
						cancelText={`Revert`}
						confirmText={'Confirm Changes'}
						onConfirm={() => {
							onSave()
							cb(true)
							toast.dismiss(toastId)
						}}
						onCancel={() => {
							cb(false)
							toast.dismiss(toastId)
						}}
					/>,
					{
						position: toast.POSITION.TOP_CENTER,
						autoClose: false,
						draggable: false,
					}
				)
			} else cb(true)
		}
	}
	return actions && config && (formId || config.mapId) ? (
		<div className="flex justify-center fixed z-[1049] inset-0 pointer-events-none">
			<div className="pointer-events-auto bg-white overflow-y-auto overflow-x-hidden resize border border-black/20 rounded mt-2 flex flex-col max-w-[90vw] max-h-[90vh] w-[80vw] h-[45vh]">
				<div className="p-2 border-b border-black/5 bg-neutral-light flex justify-between items-center sticky top-0 z-[3]">
					<div>
						{formStack.length > 1 && (
							<Icon
								css="margin-right: var(--spacing);"
								onClick={wrapInLeavingConfirmation(onClickBack)}
								name="arrow-left"
							/>
						)}
						{!config.graph
							? `${prettifyTypeEnum(actions[config.id]?.type || '')} Action Form`
							: 'Action Flow Chart'}
					</div>
					{!config.graph && (
						<div>
							<Button
								color="primary"
								disabled={!saveRequired}
								onClick={onSave}
								css="margin-right: 4px;">
								{saveRequired && <Icon css="margin-right: 4px;" name="exclamation-circle" />}
								Confirm Changes
							</Button>
							<Button onClick={() => playAction(config.id)} css="margin-right: 4px;">
								<IoMdPlay color="white" />
							</Button>
							{(!config.objectId || !config.mapId) && (
								<Button color="danger" onClick={onDelete}>
									<Icon name="trash" />
								</Button>
							)}
						</div>
					)}
					<Icon onClick={wrapInLeavingConfirmation(toggle)} name="times" />
				</div>
				<div className="p-2 relative flex-grow">
					{config.graph && config.id ? (
						<ActionGraph topActionId={config.id} actions={actions} />
					) : formState?.type === 'MAP_EVENT' && config.objectId ? (
						<ObjectResultsForm
							setFormState={setFormState}
							formState={formState}
							onLeave={(cb) => wrapInLeavingConfirmation(cb)}
							objectId={config.objectId}
						/>
					) : formState?.type === 'GENERAL' ? (
						<Form
							formState={formState}
							setFormState={setFormState}
							onLeave={(cb) => wrapInLeavingConfirmation(cb)}
						/>
					) : (
						<div>Form could not be rendered</div>
					)}
					<span css="color: gray; position: absolute; top: 2px; right: 2px; font-size: 10px;">
						ID: {config.id}
					</span>
				</div>
			</div>
		</div>
	) : null
}

const Icon = styled(FontAwesome)`
	cursor: pointer;
`
