import type {
	CreativeCanvasIssue as CreativeCanvasIssue_,
	CreativeCanvasIssueCriterion,
	CreativeCanvasIssueWithTeams as CreativeCanvasIssueWithTeams_,
	CreativeCanvasStationData as CreativeCanvasStationData_,
	CreativeCanvasIssueGradingOption as CreativeCansIssueGradingOption_,
	CreativeCanvasData,
	CreativeCanvasDesignMedia as CreativeCanvasDesignMedia_,
	CreativeCanvasBackgroundMedia as CreativeCanvasBackgroundMedia_,
	CreativeCanvasDataWithoutMap,
	Action,
} from '@mission.io/mission-toolkit/actions'
import { CREATIVE_CANVAS_STATION } from '@mission.io/mission-toolkit/constants'
import FontAwesome from 'react-fontawesome'
import { FaTrash } from 'react-icons/fa'

import FormComponentWrapper, { Label } from '../FormComponentWrapper'

import Select from 'react-select'

import * as React from 'react'
import { useState } from 'react'
import { Button, Label as ReactStrapLabel, Input, FormGroup } from 'reactstrap'
import { createTemporaryId, prettifyTypeEnum } from '../../../../helpers/functions'
import { omit } from 'lodash'
import {
	getNewCreativeCanvasIssue,
	getNewCreativeCanvasIssueCriterion,
} from '../../helpers/stations'
import {
	BooleanInput,
	EventResultInput,
	MapSelector,
	MediaInput,
	NumberInput,
	StringInput,
	TeacherTipsEditor,
	UrlInput,
} from './BasicFormComponents'
import { fileRestrictionsByType } from '../../helpers/algorithms'
import type { EventResultChangeProps } from './BasicFormComponents'
import { useTeams } from '../../../simulations/Teams'
import { DataEditor } from '../../../maps/Map/MapObjects/MapObjectForm/DataEditor'
import Modal from '../../../../common/AssetManager/Modal'
import {
	ALLOWED_ICON_FILE_EXTENSIONS,
	SvgInline,
} from '../../../maps/Map/MapObjects/MapObjectForm/SelectIcon'
import { MediaName } from '../../../maps/Map/MapObjects/MapObjectForm/MediaName'
import { ActionId, ActionReference } from '../../actionDefinitions'

// All ids will be strings on the front end
type CreativeCanvasStationData = CreativeCanvasStationData_<ActionId>
type CreativeCanvasIssueWithTeams = CreativeCanvasIssueWithTeams_<ActionId>
type CreativeCanvasIssue = CreativeCanvasIssue_<ActionId>
type CreativeCanvasIssueGradingOption = CreativeCansIssueGradingOption_<ActionId>
type CreativeCanvasDesignMedia = CreativeCanvasDesignMedia_<ActionId>
type CreativeCanvasBackgroundMedia = CreativeCanvasBackgroundMedia_<ActionId>

type EventResultProps = {
	actionId: ActionId
	onEventResultChange: (eventResultChange: EventResultChangeProps) => unknown
	onLeave: (arg0: (arg0: boolean) => void) => () => void
	actions: ReadonlyArray<Action<ActionId> | ActionReference>
}

/**
 * This component is used to edit the station data for a creative canvas station
 *
 * @param stationData - The station data to edit
 * @param onChange - A function to call when the station data is changed
 * @param ...eventResultProps - Props to pass to the event result components
 */
export function CreativeCanvasStationDataEditor({
	stationData,
	onChange,
	...eventResultProps
}: {
	stationData: CreativeCanvasStationData
	onChange: (newStationData: CreativeCanvasStationData) => unknown
} & EventResultProps) {
	return (
		<>
			<VariantEditor stationData={stationData} onChange={onChange} />
			<div css="border: 1px solid black; border-radius: 4px; padding: var(--spacing);">
				Tools for all teams
				<CanvasTools
					{...stationData}
					onChange={(update) => onChange({ ...stationData, ...update })}
				/>
			</div>
			<FormComponentWrapper
				label={
					stationData.variant === CREATIVE_CANVAS_STATION.VARIANT.GENERAL_ISSUE ? 'Issue' : 'Issues'
				}>
				{stationData.variant === CREATIVE_CANVAS_STATION.VARIANT.ISSUE_PER_TEAM ? (
					<IssuesEditor
						issues={stationData.issues}
						stationData={stationData}
						onChange={(issues) => onChange({ ...stationData, issues })}
						{...eventResultProps}
					/>
				) : (
					<IssueEditor
						issue={stationData.issue}
						stationData={stationData}
						onChange={(issue) => onChange({ ...stationData, issue })}
						{...eventResultProps}
					/>
				)}
			</FormComponentWrapper>
		</>
	)
}

/**
 * This component is used to edit the variant of a creative canvas station
 */
function VariantEditor({
	stationData,
	onChange,
}: {
	stationData: CreativeCanvasStationData
	onChange: (stationData: CreativeCanvasStationData) => unknown
}) {
	return (
		<FormComponentWrapper label="Issue Type" id="issue-type">
			<Select
				inputId="issue-type"
				value={{ value: stationData.variant, label: prettifyTypeEnum(stationData.variant) }}
				onChange={(e) => {
					if (!e) {
						return
					}
					if (e.value === CREATIVE_CANVAS_STATION.VARIANT.GENERAL_ISSUE) {
						onChange({
							...omit(stationData, 'issues'),
							variant: CREATIVE_CANVAS_STATION.VARIANT.GENERAL_ISSUE,
							issue:
								'issue' in stationData ? stationData.issue : omit(stationData.issues[0], 'teams'),
						})
					} else {
						onChange({
							...omit(stationData, 'issue'),
							variant: CREATIVE_CANVAS_STATION.VARIANT.ISSUE_PER_TEAM,
							issues:
								'issues' in stationData
									? stationData.issues
									: [
											{
												...stationData.issue,
												teams: [],
											},
									  ],
						})
					}
				}}
				options={Object.keys(CREATIVE_CANVAS_STATION.VARIANT).map((variant) => ({
					value: variant as keyof typeof CREATIVE_CANVAS_STATION.VARIANT,
					label: prettifyTypeEnum(variant),
				}))}
			/>
		</FormComponentWrapper>
	)
}

/**
 * Allows editing multiple creative canvas issues
 */
function IssuesEditor({
	issues,
	onChange,
	onEventResultChange,
	stationData,
	...eventResultProps
}: {
	issues: CreativeCanvasIssueWithTeams[]
	onChange: (issues: CreativeCanvasIssueWithTeams[]) => unknown
	stationData: CreativeCanvasStationData
} & EventResultProps) {
	const usedTeams = new Set<string>()
	issues.forEach((issue) => issue.teams.forEach((team) => usedTeams.add(team)))

	return (
		<>
			{issues.map((issue, index) => (
				<IssueEditor
					key={index}
					issue={issue}
					onChange={(issue) => {
						const newIssues = [...issues]
						newIssues[index] = issue
						onChange(newIssues)
					}}
					stationData={stationData}
					onEventResultChange={(e) => onEventResultChange({ ...e, issueIndex: index })}
					usedTeams={usedTeams}
					uniqueId={String(index)}
					{...eventResultProps}
				/>
			))}
			<Button
				onClick={() => onChange([...issues, { ...getNewCreativeCanvasIssue(), teams: [] }])}
				size="sm">
				Add Issue
			</Button>
		</>
	)
}

const REQUIRED_SCORE_LABEL = 'Required Score to Pass'

/**
 * Allows editing a single creative canvas issue
 */
function IssueEditor<Issue extends CreativeCanvasIssue | CreativeCanvasIssueWithTeams>({
	stationData,
	issue,
	onChange,
	onEventResultChange,
	usedTeams,
	uniqueId,
	...eventResultProps
}: {
	issue: Issue
	stationData: CreativeCanvasStationData
	onChange: (issue: Issue) => unknown
	usedTeams?: Set<string>
	uniqueId?: string
} & EventResultProps) {
	const [isExpanded, setIsExpanded] = useState(false)

	const teams = useTeams()
	// We need stripped down versions of successMedia and failMedia for a later component
	const {
		// @ts-expect-error allow destructuring unshared fields
		successMedia: { text: _, _mediaDuration, ...successMedia },
		failMedia: { text: __, ...failMedia },
	} = issue.rubric
	return (
		<div css="border: 1px solid black; padding: var(--spacing); border-radius: 6px; &:not(:last-child) { margin-bottom: var(--spacing);}">
			{isExpanded ? (
				<>
					<div css="display: flex; gap: var(--spacing); align-items: flex-start;">
						<FormComponentWrapper
							label="Prompt"
							size="small"
							id={'issue-prompt-' + String(uniqueId)}
							css="flex: 1;">
							<StringInput
								value={issue.prompt}
								onChange={(prompt) => onChange({ ...issue, prompt })}
								id={'issue-prompt-' + String(uniqueId)}
							/>
						</FormComponentWrapper>
						<Button onClick={() => setIsExpanded(false)} size="sm" css="margin-left: auto;">
							Close
						</Button>
					</div>
					{'teams' in issue && (
						<FormComponentWrapper label="Teams" size="small">
							<TeamsEditor
								teamIds={issue.teams}
								onChange={(teams) => onChange({ ...issue, teams })}
								usedTeams={usedTeams}
							/>
						</FormComponentWrapper>
					)}
					<FormComponentWrapper label="Teacher Tips" size="small">
						<TeacherTipsEditor
							value={issue.teacherTips}
							onChange={(teacherTips) => onChange({ ...issue, teacherTips })}
						/>
					</FormComponentWrapper>
					<FormComponentWrapper
						label={REQUIRED_SCORE_LABEL}
						size="small"
						id={`required-score-to-pass-${issue._id}`}
						description="The total score required to pass this issue. The default possible score for each criteria is 1.">
						<NumberInput
							id={`required-score-to-pass-${issue._id}`}
							value={issue.rubric.requiredScore}
							onChange={(requiredScore) =>
								onChange({
									...issue,
									rubric: {
										...issue.rubric,
										requiredScore,
									},
								})
							}
						/>
					</FormComponentWrapper>
					<FormComponentWrapper label="Criteria" size="small">
						<div css="margin-inline-start: var(--spacing); display: flex; flex-direction: column; gap: var(--spacing2x);">
							{issue.rubric.criteria.map((criterion, index) => (
								<IssueCriterionEditor
									key={index}
									criterion={criterion}
									onChange={(criterion) => {
										const newCriteria = [...issue.rubric.criteria]
										newCriteria[index] = criterion
										onChange({
											...issue,
											rubric: {
												...issue.rubric,
												criteria: newCriteria,
											},
										})
									}}
									onDelete={() => {
										const newCriteria = [...issue.rubric.criteria]
										newCriteria.splice(index, 1)
										onChange({
											...issue,
											rubric: {
												...issue.rubric,
												criteria: newCriteria,
											},
										})
									}}
								/>
							))}
							<Button
								css="align-self: flex-start;"
								size="sm"
								onClick={() =>
									onChange({
										...issue,
										rubric: {
											...issue.rubric,
											criteria: [...issue.rubric.criteria, getNewCreativeCanvasIssueCriterion()],
										},
									})
								}>
								Add Criterion
							</Button>
						</div>
					</FormComponentWrapper>
					<div css="display: flex; gap: var(--spacing2x); > * { flex: 1 };">
						<div>
							<FormComponentWrapper label="Success Media" size="small">
								<MediaInput
									value={successMedia}
									onChange={(newMedia) => {
										if (!newMedia) {
											throw new Error('It should be impossible to delete success media')
										}

										onChange({
											...issue,
											rubric: {
												...issue.rubric,
												successMedia: {
													...issue.rubric.successMedia,
													...newMedia,
												},
											},
										})
									}}
									mediaType="VIDEO_OR_IMAGE"
									collection="creativeCanvas"
									allowDelete={false}
								/>
							</FormComponentWrapper>
							<FormComponentWrapper label="On Success" size="small">
								<EventResultInput
									value={issue.rubric.onSuccess}
									onChange={(e) => onEventResultChange({ ...e, eventType: 'success' })}
									{...eventResultProps}
								/>
							</FormComponentWrapper>
						</div>

						<div>
							<FormComponentWrapper label="Fail Media" size="small">
								<MediaInput
									collection="creativeCanvas"
									value={failMedia}
									onChange={(newMedia) => {
										if (!newMedia) {
											throw new Error('It should be impossible to delete fail media')
										}

										onChange({
											...issue,
											rubric: {
												...issue.rubric,
												failMedia: {
													...issue.rubric.failMedia,
													...newMedia,
												},
											},
										})
									}}
									mediaType="VIDEO_OR_IMAGE"
									allowDelete={false}
								/>
							</FormComponentWrapper>
							<FormComponentWrapper label="On Fail" size="small">
								<EventResultInput
									value={issue.rubric.onFail}
									onChange={(e) => onEventResultChange({ ...e, eventType: 'fail' })}
									{...eventResultProps}
								/>
							</FormComponentWrapper>
						</div>
					</div>
					<CanvasTools
						{...issue}
						parentStationData={stationData}
						onChange={(update) => {
							onChange({ ...issue, ...update })
						}}
					/>
				</>
			) : (
				<div css="display: flex; align-items: flex-start; justify-content: space-between;">
					<div css="flex: 1;">
						<h5>{issue.prompt || 'No prompt entered'}</h5>
						{'teams' in issue && (
							<div>
								Teams:{' '}
								{issue.teams.length
									? issue.teams
											.map(
												(teamId) =>
													teams.find((team) => team._id === teamId)?.name || 'Unknown Team 😬'
											)
											.join(', ')
									: 'None'}
							</div>
						)}
						<div>
							Required Criteria to Pass: {issue.rubric.requiredScore} /{' '}
							{issue.rubric.criteria.length} (
							{issue.rubric.criteria.filter((c) => c.required).length} required)
						</div>
					</div>
					<Button size="sm" onClick={() => setIsExpanded(true)}>
						Edit
					</Button>
				</div>
			)}
		</div>
	)
}

/**
 * Allows editing a list of team ids
 * @param props
 * @param props.teamIds The current set of selected teams
 * @param props.onChange A function to call with the new set of selected teams
 * @param props.usedTeams A set of all teams that are already used in the creative canvas
 */
export function TeamsEditor({
	teamIds,
	onChange,
	usedTeams,
	placeholder,
}: {
	teamIds: string[]
	onChange: (teams: string[]) => unknown
	usedTeams?: Set<string>
	placeholder?: string
}) {
	const teams = useTeams()
	const options = teams
		.filter(({ _id: teamId }) => !usedTeams?.has(teamId) || teamIds.includes(teamId))
		.map((team) => ({ value: team._id, label: team.name }))
	const selectedOptions = options.filter((option) => teamIds.includes(option.value))

	return (
		<Select
			placeholder={placeholder}
			isMulti
			closeMenuOnSelect={false}
			options={options}
			value={selectedOptions}
			onChange={(newOptions) => onChange(newOptions.map((option) => option.value))}
		/>
	)
}

/**
 * Allows editing a single criterion from a creative canvas issue
 */
function IssueCriterionEditor({
	criterion,
	onChange,
	className,
	onDelete,
}: {
	criterion: CreativeCanvasIssueCriterion<ActionId>
	onChange: (criterion: CreativeCanvasIssueCriterion<ActionId>) => unknown
	className?: string
	onDelete: () => unknown
}) {
	return (
		<div
			className={className}
			css={`
				display: grid;
				column-gap: var(--spacing2x);
				grid-template-columns: 1fr max(30%, 100px);
			`}>
			<FormComponentWrapper label="Text" size="small">
				<StringInput value={criterion.text} onChange={(text) => onChange({ ...criterion, text })} />
			</FormComponentWrapper>
			<div css="display: flex; justify-content: space-between; align-items: center;">
				<FormComponentWrapper label="Required" size="small">
					<BooleanInput
						value={criterion.required}
						onChange={(required) => onChange({ ...criterion, required })}
					/>
				</FormComponentWrapper>
				<Button
					css="align-self: flex-start;"
					size="sm"
					color="danger"
					onClick={() => {
						onDelete()
					}}>
					<FontAwesome name="trash" />
				</Button>
			</div>
			<FormComponentWrapper label="Display Type" size="small">
				<Select<{
					label: string
					value: keyof typeof CREATIVE_CANVAS_STATION.RUBRIC.CRITERIA.DISPLAY_TYPE
				}>
					value={{ value: criterion.displayType, label: prettifyTypeEnum(criterion.displayType) }}
					onChange={(e) => e && onChange({ ...criterion, displayType: e.value })}
					options={Object.keys(CREATIVE_CANVAS_STATION.RUBRIC.CRITERIA.DISPLAY_TYPE).map(
						(displayType) => ({
							value:
								displayType as keyof typeof CREATIVE_CANVAS_STATION.RUBRIC.CRITERIA.DISPLAY_TYPE,
							label: prettifyTypeEnum(displayType),
						})
					)}
				/>
			</FormComponentWrapper>
			<FormComponentWrapper
				label="Grading Options"
				size="small"
				css="grid-column-start: 1;"
				collapsible={true}>
				{({ isCollapsed }) =>
					isCollapsed ? (
						criterion.gradingOptions.map((option) => `${option.text} (${option.score})`).join(', ')
					) : (
						<GradingOptionsEditor
							idPrefix={`grading-options-${criterion._id}`}
							value={criterion.gradingOptions}
							onChange={(gradingOptions) => onChange({ ...criterion, gradingOptions })}
						/>
					)
				}
			</FormComponentWrapper>
		</div>
	)
}

/**
 * Allows editing a list of grading options on a criterion. This includes editing and deleting existing options, as well as adding new options.
 */
function GradingOptionsEditor({
	value: gradingOptions,
	onChange,
	idPrefix,
	className,
}: {
	value: CreativeCanvasIssueGradingOption[]
	onChange: (value: CreativeCanvasIssueGradingOption[]) => unknown
	idPrefix: string
	className?: string
}) {
	return (
		<>
			<div css="display: grid; grid-template-columns: 1fr 1fr auto auto; gap: var(--spacing) var(--spacing2x); margin-left: var(--spacing);">
				<Label size="small" id={`${idPrefix}-text`}>
					Text
				</Label>
				<Label
					size="small"
					id={`${idPrefix}-score`}
					description={`The score from all criteria for this issue will be summed and compared against "${REQUIRED_SCORE_LABEL}"`}>
					Score
				</Label>
				<Label
					size="small"
					id={`${idPrefix}-correct`}
					description={`Whether this option is "correct". Required criteria will only pass if a "correct" option is selected by the teacher.`}>
					Correct
				</Label>
				{gradingOptions.map((option, index) => {
					function replaceOption(newOption: CreativeCanvasIssueGradingOption) {
						onChange(gradingOptions.map((o, i) => (i === index ? newOption : o)))
					}
					return (
						<React.Fragment key={option._id}>
							<StringInput
								value={option.text}
								onChange={(text) => replaceOption({ ...option, text })}
								css="grid-column-start: 1;"
							/>
							<NumberInput
								value={option.score}
								onChange={(score) => replaceOption({ ...option, score })}
							/>
							<BooleanInput
								value={option.correct}
								onChange={(correct) => replaceOption({ ...option, correct })}
							/>
							<Button
								size="sm"
								color="danger"
								onClick={() => {
									onChange(gradingOptions.filter((o, i) => i !== index))
								}}>
								<FontAwesome name="trash" />
							</Button>
						</React.Fragment>
					)
				})}
			</div>
			<Button
				size="sm"
				color="link"
				onClick={() =>
					onChange([
						...gradingOptions,
						{ _id: createTemporaryId(), text: '', score: 0, correct: false },
					])
				}>
				Add Grading Option
			</Button>
		</>
	)
}

type CanvasToolsProps = {
	backgroundMedia?: CreativeCanvasBackgroundMedia | null
	data: CreativeCanvasData<string>[]
	designMedia: Array<CreativeCanvasDesignMedia>
	canvasTools: Array<keyof typeof CREATIVE_CANVAS_STATION.CANVAS_TOOLS>
}

/**
 * Allows editing the tools used during the creative canvas, including `data`, `backgroundMedia`, and `designMedia`.
 * This can be general tools available for all issues, or specific tools for a particular issue.
 */
function CanvasTools({
	backgroundMedia,
	data,
	designMedia,
	canvasTools,
	onChange,
	parentStationData,
}: CanvasToolsProps & {
	parentStationData?: CreativeCanvasStationData
	onChange: (newCanvasTools: Partial<CanvasToolsProps>) => unknown
}) {
	const possibleCanvasTools = Object.keys(CREATIVE_CANVAS_STATION.CANVAS_TOOLS) as Array<
		keyof typeof CREATIVE_CANVAS_STATION.CANVAS_TOOLS
	>
	const parentCanvasTools = parentStationData?.canvasTools || []
	const parentCanvasToolsSet = parentStationData ? new Set(parentCanvasTools) : null
	const useableCanvasToolsSet = new Set([...parentCanvasTools, ...canvasTools])
	return (
		<>
			<FormComponentWrapper label="Data" size="small" collapsible>
				{({ isCollapsed }) => (
					<CreativeCanvasDataEditor
						data={data}
						onChange={(newData) => onChange({ data: newData })}
						isCollapsed={isCollapsed}
					/>
				)}
			</FormComponentWrapper>
			<FormComponentWrapper label="Background Media" size="small">
				<UrlInput
					collection="creativeCanvas"
					restrictions={fileRestrictionsByType.IMAGE}
					value={backgroundMedia?.url}
					onChange={(newUrl) => {
						onChange({
							backgroundMedia: newUrl
								? {
										type: 'IMAGE',
										url: newUrl,
										_id: createTemporaryId(),
								  }
								: null,
						})
					}}
				/>
			</FormComponentWrapper>
			<FormComponentWrapper label="Design Media" size="small" collapsible>
				{({ isCollapsed }) => (
					<DesignMediaEditor
						designMedia={designMedia}
						onChange={(newDesignMedia) => onChange({ designMedia: newDesignMedia })}
						isCollapsed={isCollapsed}
					/>
				)}
			</FormComponentWrapper>
			<FormComponentWrapper label="Allowed Canvas Tools" size="small">
				{possibleCanvasTools.map((toolEnum) => {
					const isChecked = useableCanvasToolsSet.has(toolEnum)
					const canRemove = parentCanvasToolsSet ? !parentCanvasToolsSet.has(toolEnum) : true
					const disabled = !canRemove && isChecked
					return (
						<FormGroup check inline key={toolEnum}>
							<Input
								type="checkbox"
								disabled={disabled}
								onChange={(e) => {
									if (e.target.checked) {
										onChange({
											canvasTools: [...canvasTools, toolEnum],
										})
									} else {
										onChange({
											canvasTools: canvasTools.filter((tool) => tool !== toolEnum),
										})
									}
								}}
								checked={isChecked}
							/>
							<Label
								check
								css="font-weight: 500;"
								id={`canvas-tool-${toolEnum}`}
								description={
									disabled
										? 'To remove this tool, first unselect it above on the main action.'
										: undefined
								}>
								{prettifyTypeEnum(toolEnum)}
							</Label>
						</FormGroup>
					)
				})}
			</FormComponentWrapper>
		</>
	)
}

/**
 * Allows editing an array of `CreativeCanvasData`.
 */
function CreativeCanvasDataEditor({
	data,
	onChange,
	isCollapsed,
}: {
	data: CreativeCanvasData<string>[]
	onChange: (data: CreativeCanvasData<string>[]) => unknown
	isCollapsed: boolean
}) {
	return (
		<div css="margin-top: var(--spacing);">
			{isCollapsed ? (
				data.length + ' data items'
			) : (
				<>
					{data.map((datum, i) => (
						<div
							key={i}
							css={`
								box-shadow: lightgray 1px 1px 5px;
								border-radius: 4px;
								padding: var(--spacing);
								margin-bottom: var(--spacing);
							`}>
							{datum.type === 'MAP' ? (
								<>
									<h3>Map</h3>
									<MapSelector
										value={datum.mapId}
										onChange={(mapId) => {
											const newData = [...data]
											newData[i] = { ...datum, mapId: mapId || '' }
											onChange(newData)
										}}
										showMap={false}
									/>
									<div css="display: flex; gap: var(--spacing4x); align-items: center;">
										<Button
											onClick={() => {
												const newData = [...data]
												newData.splice(i, 1)
												onChange(newData)
											}}
											color="danger">
											<FaTrash size={20} />
										</Button>
										All data from this map will be made available to the students
									</div>
								</>
							) : (
								<DataEditor
									type="CREATIVE_CANVAS_DATA"
									data={datum}
									onChange={(newDatum) => {
										const newData = [...data]
										newData[i] = newDatum
										onChange(newData)
									}}
									CustomFormGroup={NameAndUrlEditor}
									includePrescannedField={false}
									onDelete={() => {
										const newData = [...data]
										newData.splice(i, 1)
										onChange(newData)
									}}
								/>
							)}
						</div>
					))}
					<Button
						size="sm"
						onClick={() =>
							onChange([
								...data,
								{ type: 'TEXT', text: '', relevance: 0, _id: createTemporaryId() },
							])
						}>
						Add Data
					</Button>
					<Button
						size="sm"
						onClick={() =>
							onChange([...data, { type: 'MAP', mapId: '', _id: createTemporaryId() }])
						}
						css="margin-left: var(--spacing);">
						Add Data from Map
					</Button>
				</>
			)}
		</div>
	)
}

/*
 * Allows editing an array of design media.
 */
function DesignMediaEditor({
	designMedia,
	onChange,
	isCollapsed,
}: {
	designMedia: CreativeCanvasDesignMedia[]
	onChange: (designMedia: CreativeCanvasDesignMedia[]) => unknown
	isCollapsed: boolean
}) {
	return (
		<div css="margin-top: var(--spacing);">
			<>
				<div
					css={`
						display: flex;
						flex-wrap: wrap;
						gap: var(--spacing);
					`}>
					{designMedia.map((media, index) => (
						<div
							key={index}
							className={!isCollapsed ? 'edit-mode' : ''}
							css="position: relative; box-shadow: lightgray 1px 1px 5px; border-radius: 4px; padding: var(--spacing); &.edit-mode { min-width: 250px; }">
							{!isCollapsed ? (
								<>
									<Button
										css="position: absolute; top: var(--spacing); right: var(--spacing);"
										size="sm"
										color="danger"
										onClick={() => {
											const newMedia = [...designMedia]
											newMedia.splice(index, 1)
											onChange(newMedia)
										}}>
										<FontAwesome name="trash" />
									</Button>
									<UrlInput
										collection="creativeCanvas"
										restrictions={fileRestrictionsByType.IMAGE}
										value={media.url}
										onChange={(newUrl) => {
											const newMedia = [...designMedia]
											newMedia[index] = {
												...newMedia[index],
												url: newUrl || '',
											}
											onChange(newMedia)
										}}
										allowDelete={false}
									/>
									<FormComponentWrapper label="Description" size="small">
										<StringInput
											value={media.text}
											onChange={(text) => {
												const newMedia = [...designMedia]
												newMedia[index] = {
													...newMedia[index],
													text,
												}
												onChange(newMedia)
											}}
										/>
									</FormComponentWrapper>
								</>
							) : (
								<div css="display: flex; flex-direction: column; align-items: center; width: 120px;">
									<img
										src={media.url}
										alt={media.text}
										css="width: 100%; height: 100px; object-fit: contain;"
									/>
									<div css="text-align: center; font-size: 0.9rem;">
										{media.text || 'No Description'}
									</div>
								</div>
							)}
						</div>
					))}
				</div>
				{!isCollapsed && (
					<Button
						css="margin-top: var(--spacing);"
						size="sm"
						onClick={() =>
							onChange([
								...designMedia,
								{ type: 'IMAGE', url: '', text: '', _id: createTemporaryId() },
							])
						}>
						Add Media
					</Button>
				)}
			</>
		</div>
	)
}

/**
 * NameAndUrlEditor - a react component used to edit the name and icon of a canvas data
 *
 * @param {Object} props - the react props
 * @param {CreativeCanvasDataWithoutMap<string>} props.data - the current canvas data object
 * @param {(data: CreativeCanvasDataWithoutMap<string>) => unknown} props.onChange - a callback used to  update the canvas data
 *
 * @return {React$Node}
 */
function NameAndUrlEditor({
	data,
	onChange,
}: {
	data: CreativeCanvasDataWithoutMap<string>
	onChange: (data: CreativeCanvasDataWithoutMap<string>) => unknown
}) {
	const [isAssetModalOpen, setIsAssetModalOpen] = useState(false)
	const { name, icon } = data
	return (
		<div>
			<FormGroup>
				<Modal
					restrictions={{
						allowedFileExtensions: ALLOWED_ICON_FILE_EXTENSIONS,
						mime: 'image/svg+xml',
					}}
					collection="mapIcons"
					isOpen={isAssetModalOpen}
					onClose={() => setIsAssetModalOpen(false)}
					onFileClick={(file) => {
						onChange({
							...data,
							icon: file.url,
						})
						setIsAssetModalOpen(false)
					}}
				/>
				{icon && (
					<>
						<SvgInline src={icon} />
						<span css="margin-left: 8px;" />
						<MediaName mediaUrl={icon} />
					</>
				)}
				<span css="margin-left: 8px;" />
				<Button onClick={() => setIsAssetModalOpen(true)} color="primary" size="sm">
					{icon ? 'Replace' : 'Choose'} Icon
				</Button>
				{icon ? (
					<>
						<span css="margin-left: 8px;" />
						<Button
							onClick={() => {
								const updatedCopy = { ...data }
								delete data.icon
								onChange(updatedCopy)
							}}
							color="danger"
							size="sm">
							Remove Icon
						</Button>
					</>
				) : null}
			</FormGroup>
			<FormGroup>
				<ReactStrapLabel>Name</ReactStrapLabel>
				<Input
					type="text"
					value={name ?? ''}
					onChange={(e) => {
						onChange({
							...data,
							name: e.target.value,
						})
					}}
				/>
			</FormGroup>
		</div>
	)
}
