import type {
	AutomatedSimulation,
	BasicAutomatedSimulation,
	ControlSet,
	MapEventResult,
	SimulationTeam,
	StationQuestions,
} from '../../types/AutomatedSimulation'
import type {
	QueryClient,
	UseMutationOptions,
	UseMutationResult,
	UseQueryResult,
} from 'react-query'

import { toast } from 'react-toastify'
import { ONE_MINUTE } from '../../helpers/constants'
import { forEachEntry } from '../../helpers/functions'
import { EVENT_RESULT_ID_UPDATERS } from './helpers/eventResults'
import { actions as tilesActions } from '../../setup/tiles'
import axios from 'axios'
import produce from 'immer'
import { keyBy } from 'lodash'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { setEditorSimulation } from '../../reducers/simulationEditor'
import type {
	Action,
	ActionMap,
	CreativeCanvasIssue,
	CreativeCanvasIssueWithTeams,
} from '@mission.io/mission-toolkit/actions'
import { CREATIVE_CANVAS_STATION } from '@mission.io/mission-toolkit/constants'
import { config } from '../../config'

export const AUTOMATED_SIMULATIONS_QUERY_KEYS = {
	all: ['automatedSimulations'] as const,
	allWithQuery: (query: { archived: boolean }): ['automatedSimulations', { archived: boolean }] => [
		...AUTOMATED_SIMULATIONS_QUERY_KEYS.all,
		query,
	],
	single: (id: string): ['automatedSimulations', string] => [
		...AUTOMATED_SIMULATIONS_QUERY_KEYS.all,
		id,
	],
}

/**
 * Uses react-query to fetch all automated simulations
 * @returns {UseQueryResult<BasicAutomatedSimulation[]>} - the UseQueryResult for all automated simulations
 */
export function useAutomatedSimulations(
	archived = false
): UseQueryResult<BasicAutomatedSimulation[]> {
	return useQuery(
		AUTOMATED_SIMULATIONS_QUERY_KEYS.allWithQuery({ archived }),
		async () => {
			const { data } = await axios.get(
				`${config.simulationsApiUrl}/api/simulations?automated=true&archived=${archived}`
			)
			if (!data.automated) {
				throw new Error('There was an error retrieving automated simulations. Please refresh')
			}

			return data.simulations
		},
		{
			staleTime: 10 * ONE_MINUTE,
		}
	)
}

/**
 * Fetch a single automated simulation by its id.
 */
export function fetchAutomatedSimulation(id: string): Promise<AutomatedSimulation> {
	return axios.get(`${config.simulationsApiUrl}/api/simulations/${id}`).then(({ data }) => {
		if (!data.automated) {
			throw new Error('Expected automated simulation')
		}

		return {
			...data.simulation,
			actions: keyBy(data.simulation.actions, '_id'),
			mapIds: data.simulation.maps.map((map: { _id: string }) => map._id),
		}
	})
}

/**
 * Uses react-query to fetch a single simulation by its id
 * @param {string} id - the id of the simulation to fetch
 * @returns {UseQueryResult<AutomatedSimulation>} - the UseQueryResult for the simulation
 */
export function useAutomatedSimulation(id: string): UseQueryResult<AutomatedSimulation> {
	const dispatch = useDispatch()
	return useQuery(
		AUTOMATED_SIMULATIONS_QUERY_KEYS.single(id),
		async () => {
			const simulation = await fetchAutomatedSimulation(id)
			dispatch(tilesActions.maps.store.addMany(simulation.maps))
			return simulation
		},
		{
			staleTime: 10 * ONE_MINUTE,
		}
	)
}

/**
 * Updates a simulation in the react-query cache. If `options.replace` is true, the simulation will be replaced with the given `simulationData`.
 * Otherwise, the `simulationData` will be merged with the existing simulation. In this case, if the simulation does not exist in the cache,
 * nothing will happen.
 *
 * @param {string} simulationId - the id of the simulation to update
 * @param {Partial<AutomatedSimulation>} simulationData - the partial AutomatedSimulation to update the simulation with
 * @param {QueryClient} queryClient - the react-query query client to use
 * @param options
 * @param {boolean} options.replace - if true, the simulation will be replaced with the given simulationData. Otherwise, the simulationData
 *                                will be merged with the existing simulation
 * @returns {boolean} - true if the simulation was updated, false otherwise
 */
export function updateAutomatedSimulationInReactQuery(
	simulationId: string,
	simulationData: Partial<AutomatedSimulation>,
	queryClient: QueryClient,
	{
		replace = false,
	}: {
		replace?: boolean
	} = {}
): boolean {
	let newSimulation = simulationData

	if (!replace) {
		const currentSimulation: AutomatedSimulation | void = queryClient.getQueryData(
			AUTOMATED_SIMULATIONS_QUERY_KEYS.single(simulationId)
		)

		if (!currentSimulation) {
			return false
		}

		newSimulation = { ...currentSimulation, ...newSimulation }
	}

	queryClient.setQueryData(AUTOMATED_SIMULATIONS_QUERY_KEYS.single(simulationId), newSimulation)
	return true
}
type UpdateReturn = {
	simulation?: AutomatedSimulation
	shouldUpdateActions: boolean
}

type UpdateError = {
	general: null | string
	actions: null | {
		message: string
		failedAction: unknown
	}
}
type UpdateArgument = {
	simulationId: string
	simulationData: Partial<AutomatedSimulation>
	lastSavedActions?: ActionMap<string>
	mustUpdateStore?: boolean
}

/**
 * Returns a UseMutationResult where the mutate function will update the simulation with the given id.
 * Performs all necessary functionality to update actions, maps, the simulation on the server, and then
 * update any front end stores that need to be updated.
 * @param {boolean} options.mustUpdateStore - If the simulation store must be updated. If the store must be updated and there is no simulation found
 * in the store, than an error is thrown. If the store does not have to be updated and a simulation is not found, then no error is thrown. (This is useful when archiving and
 * restoring simulations)
 */
export function useUpdateAutomatedSimulation(
	options?: UseMutationOptions<UpdateReturn, UpdateError | Error, UpdateArgument, unknown>
): UseMutationResult<UpdateReturn, UpdateError | Error, UpdateArgument, unknown> {
	const queryClient = useQueryClient()
	const dispatch = useDispatch()
	return useMutation(
		async ({
			simulationId,
			simulationData: newSimulationData,
			lastSavedActions,
			mustUpdateStore,
		}: UpdateArgument) => {
			const {
				actions,
				initialScreenActionId,
				mapEventResults,
				standards,
				...generalSimulationData
			} = newSimulationData

			// replace standards with standard ids for updating
			if (standards) {
				// @ts-expect-error We want to set `standards` on the generalSimulationData so that standards can be updated
				generalSimulationData.standards = standards.map((standard) => standard._id).filter(Boolean)
			}
			const shouldUpdateActions =
				!!actions && !!initialScreenActionId && !!mapEventResults && !!lastSavedActions
			const error: UpdateError = {
				general: null,
				actions: null,
			}

			if (shouldUpdateActions) {
				const response = await axios
					.put(`${config.simulationsApiUrl}/api/simulations/${simulationId}/actions`, {
						actionIds: Object.keys(actions), // pass actionIds
						updatedActions: determineUpdatedActions(lastSavedActions as ActionMap<string>, actions),
						initialScreenActionId,
						mapEventResults,
					})
					.catch((e) => {
						error.actions = {
							message: e.response?.data?.message || 'Unable to save actions',
							failedAction: e.response?.data?.failedAction,
						}
					})

				if (error.actions) {
					throw error
				}

				if (response?.data?.updatedIds) {
					const updatedActionIdProperties = getUpdatedActionIds(
						initialScreenActionId,
						actions,
						mapEventResults,
						newSimulationData.stationQuestions,
						newSimulationData.teams,
						response.data.updatedIds
					)

					// update fields in `generalSimulationData` that depend on new action ids
					if (updatedActionIdProperties.stationQuestions) {
						generalSimulationData.stationQuestions = updatedActionIdProperties.stationQuestions
					}

					if (updatedActionIdProperties.teams) {
						generalSimulationData.teams = updatedActionIdProperties.teams
					}

					newSimulationData = { ...newSimulationData, ...updatedActionIdProperties }
				}
			}

			await axios
				.patch(`${config.simulationsApiUrl}/api/simulations/` + simulationId, {
					simulationData: generalSimulationData,
				})
				.catch((e) => {
					error.general = e?.response?.data?.message || 'Unable to update general simulation data'
				})

			if (error.general) {
				throw error
			}

			// Update the local stores with the new simulation data
			const simulation: undefined | AutomatedSimulation = queryClient.getQueryData(
				AUTOMATED_SIMULATIONS_QUERY_KEYS.single(simulationId)
			)

			updateAutomatedSimulationInReactQuery(simulationId, newSimulationData, queryClient)
			queryClient.invalidateQueries(AUTOMATED_SIMULATIONS_QUERY_KEYS.all)

			if (!simulation) {
				if (!mustUpdateStore) return { shouldUpdateActions }
				throw new Error(
					'If there is no simulation in the cache, then we do not have a full simulation to use to update the store'
				)
			}

			const newSimulation: AutomatedSimulation = {
				...simulation,
				...newSimulationData,
			}

			if (simulation.mapIds) {
				// Delete any maps that were removed from the simulation
				const oldMapIds = simulation.mapIds
				const newMapIds = new Set(newSimulation.mapIds)
				oldMapIds
					.filter((mapId) => !newMapIds.has(mapId))
					.forEach((mapId) => dispatch(tilesActions.maps.store.deleteMap(mapId)))
			}

			dispatch(setEditorSimulation(newSimulation, false, shouldUpdateActions))
			const result: UpdateReturn = { simulation: newSimulation, shouldUpdateActions }
			return result
		},
		options
	)
}

/**
 * Determines which current actions were updated since the last save
 * @param { ActionMap } lastSavedActions
 * @param { ActionMap } currentActions
 * @return Action[] a list of updated actions
 */
function determineUpdatedActions(
	lastSavedActions: ActionMap<string>,
	currentActions: ActionMap<string>
): ActionMap<string> {
	const updatedActions: ActionMap<string> = {}
	const currentActionKeys = Object.keys(currentActions)
	currentActionKeys.forEach((key: string) => {
		if (currentActions[key] !== lastSavedActions[key]) {
			updatedActions[key] = currentActions[key]
		}
	})
	return updatedActions
}

type GetUpdatedActionIdsReturn = {
	initialScreenActionId: string
	actions: ActionMap<string>
	mapEventResults: Record<string, MapEventResult>
	stationQuestions?: StationQuestions
	teams?: SimulationTeam[]
}

/**
 * Given map of updated ids from the server and all data that includes action ids, return all of the same data but with
 * any temporary ids swapped out with the real ids from the server.
 */
function getUpdatedActionIds(
	initialScreenActionId: string,
	actions: ActionMap<string>,
	mapEventResults: Record<string, MapEventResult>,
	stationQuestions: StationQuestions | null | undefined,
	teams: SimulationTeam[] | null | undefined,
	updatedIds: Record<string, string>
): GetUpdatedActionIdsReturn {
	// Gets the updated id for `id` if available. Otherwise returns the argument
	function getUpdatedId(id: string) {
		return updatedIds[id] || id
	}

	const returnValue: GetUpdatedActionIdsReturn = {
		initialScreenActionId: getUpdatedId(initialScreenActionId),
		actions: getUpdatedActions(actions, getUpdatedId),
		mapEventResults: getUpdatedMapEventResults(mapEventResults, getUpdatedId),
	}

	if (stationQuestions) {
		returnValue.stationQuestions = getUpdatedStationQuestions(stationQuestions, getUpdatedId)
	}

	if (teams) {
		returnValue.teams = teams.map((team) => {
			return { ...team, _id: getUpdatedId(team._id) }
		})
	}

	return returnValue
}

/**
 * Gets a new actions object using `getUpdatedId` to update all client ids throughout the actions.
 * This includes the ids on the actions, the keys of the `actions` object (which are the action ids), and
 * all action ids reference in any event results (e.g. onStart, onEnd, questionInfo, etc).
 *
 * Also includes ids of options on questionInfo for CULMINATING_MOMENT actions, and ids of targets on ACTIVATE_STATION actions
 */
function getUpdatedActions(
	actions: ActionMap<string>,
	getUpdatedId: (arg0: string) => string
): ActionMap<string> {
	const newActions: ActionMap<string> = {}
	Object.keys(actions).forEach((actionId) => {
		const newAction: Action<string> = produce(actions[actionId], (action: Action<string>) => {
			action._id = getUpdatedId(action._id)
			Object.keys(action).forEach((actionKey) => {
				if (actionKey in EVENT_RESULT_ID_UPDATERS) {
					action[actionKey as keyof Action<string>] = EVENT_RESULT_ID_UPDATERS[
						actionKey as keyof typeof EVENT_RESULT_ID_UPDATERS
					](action, getUpdatedId)
				}
			})

			if (action.type === 'CULMINATING_MOMENT_SCREEN') {
				action.questionInfo.options.forEach((option) => {
					option._id = getUpdatedId(option._id)
				})
			} else if (action.type === 'ACTIVATE_STATION') {
				if (
					action.stationData.stationId === 'DEFENSE_PLUS' ||
					action.stationData.stationId === 'TRACTOR_BEAM_PLUS'
				) {
					action.stationData.targets.forEach((target) => {
						target._id = getUpdatedId(target._id)
					})
				} else if (action.stationData.stationId === 'CREATIVE_CANVAS') {
					if (action.stationData.variant === CREATIVE_CANVAS_STATION.VARIANT.ISSUE_PER_TEAM) {
						action.stationData.issues.forEach((issue) => {
							updateIdsInIssue(issue, getUpdatedId)
						})
					} else {
						updateIdsInIssue(action.stationData.issue, getUpdatedId)
					}
				}
			} else if (action.type === 'COLLABORATIVE_CULMINATING_MOMENT_SCREEN') {
				action.canvasActionId = getUpdatedId(action.canvasActionId)
			}
		})
		newActions[getUpdatedId(actionId)] = newAction
	})
	return newActions
}

/**
 * Replace all ids in the given `issue` using `getUpdatedId`
 */
function updateIdsInIssue(
	issue: CreativeCanvasIssue<string> | CreativeCanvasIssueWithTeams<string>,
	getUpdatedId: (arg0: string) => string
) {
	issue._id = getUpdatedId(issue._id)
	issue.rubric.criteria.forEach((criterion) => {
		criterion._id = getUpdatedId(criterion._id)
		criterion.gradingOptions.forEach((gradingOption) => {
			gradingOption._id = getUpdatedId(gradingOption._id)
		})
	})

	if ('teams' in issue) {
		for (let i = 0; i < issue.teams.length; i++) {
			issue.teams[i] = getUpdatedId(issue.teams[i])
		}
	}
}

/**
 * Updates the mapEventResults by replacing all actionIds with the id returned from getUpdatedId
 */
function getUpdatedMapEventResults(
	allMapEventResults: Record<string, MapEventResult>,
	getUpdatedId: (previousId: string) => string
): Record<string, MapEventResult> {
	const result: Record<string, MapEventResult> = {}
	forEachEntry(allMapEventResults, (mapEventResult, mapId: string) => {
		result[mapId] = {
			objects: {},
			onAllObjectsScanned: [],
		}
		forEachEntry(mapEventResult.objects, (objectResults, objectId: string) => {
			result[mapId].objects[objectId] = {}
			forEachEntry(objectResults, (actionIds, eventKey) => {
				result[mapId].objects[objectId][eventKey] = actionIds.map(getUpdatedId)
			})
		})
		result[mapId].onAllObjectsScanned = mapEventResult.onAllObjectsScanned.map(getUpdatedId)
	})
	return result
}

/**
 * Gets a new stationQuestions object where all actionIds in the provided `stationQuestions` are replaced with the id
 * returned from the `getUpdatedId` function.
 */
function getUpdatedStationQuestions(
	stationQuestions: StationQuestions,
	getUpdatedId: (arg0: string) => string
): StationQuestions {
	const newStationQuestions: StationQuestions = {}
	forEachEntry(stationQuestions, (value, actionId: string) => {
		newStationQuestions[getUpdatedId(actionId)] = value
	})
	return newStationQuestions
}

type CreateArg = {
	name: string
	controlSet: ControlSet
}

/**
 * Creates a new automated simulation on the server and updates the react-query cache with the new simulation.
 */
export function useCreateSimulation(): UseMutationResult<string, unknown, CreateArg> {
	const queryClient = useQueryClient()
	return useMutation(async ({ name, controlSet }: CreateArg) => {
		const { data } = await axios
			.post(`${config.simulationsApiUrl}/api/simulations`, { name, controlSet, automated: true })
			.catch((error) => {
				throw new Error(error?.response?.data?.message)
			})

		if (!data.automated) {
			throw new Error(
				'There was an error while creating the simulation. Please refresh the page and check whether the simulation was created.'
			)
		}

		const simulation: AutomatedSimulation & {
			actions: Array<Action<string>>
		} = data.simulation
		updateAutomatedSimulationInReactQuery(
			simulation._id,
			{ ...simulation, actions: keyBy(simulation.actions, '_id') },
			queryClient,
			{
				replace: true,
			}
		)
		return simulation._id
	})
}

/**
 * Creates a duplicated simulation on the server and returns the new simulation id.
 */
export function useDuplicateSimulation(): UseMutationResult<
	string,
	unknown,
	{ name: string; id: string; transformTo?: ControlSet }
> {
	return useMutation(
		async ({ name, id, transformTo }: { name: string; id: string; transformTo?: ControlSet }) => {
			const { data } = await axios
				.put(`${config.simulationsApiUrl}/api/simulations/duplicate/${id}`, { name, transformTo })
				.catch((error) => {
					if (error?.response?.data?.message) {
						throw new Error(error?.response?.data?.message)
					}
					console.error(error)
					throw error
				})

			if (data.transformErrorMessage) {
				toast.error(data.transformErrorMessage)
			}

			return data.newSimulationId
		}
	)
}
