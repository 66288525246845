import React from 'react'
import { sortBy } from 'lodash'

import {
	Card,
	CardBody,
	CardHeader,
	DuplicateButton,
	CardFooter,
	ArchiveButton,
	RestoreButton,
} from './SimulationCard'
import { Spinner } from '../../common/Spinner'
import { useAutomatedSimulations } from '../automatedSimulations/queries'
/**
 * Displays a list of all automated simulations. If the list has not been fetched yet, displays
 * a spinner.
 */

export default function AutomatedSimulationList({
	filter,
	archived = false,
}: {
	filter: (simulation: { name: string }) => boolean
	archived?: boolean
}): JSX.Element {
	const { data: simulations_, isLoading, error } = useAutomatedSimulations(archived)
	const simulations = sortBy(simulations_?.filter(filter) ?? [], 'name')
	return (
		<div>
			<h2 className="text-2xl font-bold my-2">{archived && 'Archived '}Automated Simulations</h2>
			{error ? (
				'Error Loading Automated Simulations'
			) : isLoading ? (
				<Spinner />
			) : (
				simulations.map((simulation) => (
					<Card key={simulation._id}>
						<CardHeader text={simulation.name}>
							{archived ? (
								<RestoreButton simulation={simulation} />
							) : (
								<>
									<ArchiveButton simulation={simulation} />
									<DuplicateButton simulation={simulation} />
								</>
							)}
						</CardHeader>
						<CardBody data={[['Control Set', simulation.controlSet]]} />
						{!archived && <CardFooter id={simulation._id} />}
					</Card>
				))
			)}
		</div>
	)
}
